import { hot } from "react-hot-loader";
import * as React from "react";

type Props = {
  disabled: boolean;
  sensors: Status[];
  label: string;
  synchandler: (sensor: Array<string>) => void;
};

type Status = {
  Title: string;
  Name: string;
  LastCall: number;
  SensorKind: string;
  Tags: string;
};

const filterChunks = (sensors) => {
  const chunks = sensors.reduce((acc, cur, i) => {
    const chunkIndex = Math.floor(i / 10);
    if (!acc[chunkIndex]) {
      acc[chunkIndex] = [];
    }
    acc[chunkIndex].push(cur);
    return acc;
  }, []);

  // sort chunks by name
  chunks.sort((a, b) => {
    if (a[0].Name < b[0].Name) {
      return -1;
    }
    if (a[0].Name > b[0].Name) {
      return 1;
    }
    return 0;
  });
  return chunks;
};

const SensorButton = ({
  disabled,
  sensor,
  addSensor,
  removeSensor,
}: {
  sensor: Status;
  addSensor: (name: Status) => void;
  removeSensor: (name: Status) => void;
  disabled: boolean;
}) => {
  const [selected, setSelected] = React.useState(false);
  const [color, setColor] = React.useState("is-info");

  React.useEffect(() => {
    setSelected(false);
    setColor("is-info");
    // console.log("useEffect", name);
  }, [sensor]);

  // get length of string with max set to 12
  const getTitle = (title: string) => {
    if (!title) return "";
    const max = 30;
    if (title.length > max) {
      return "/ " + title.substring(0, max) + "...";
    }
    return "/ " + title;
  };

  const name = getTitle(sensor.Title);
  return (
    <button
      className={color}
      disabled={disabled}
      style={{ cursor: "pointer" }}
      onClick={(e) => {
        const s = !selected;
        setSelected(!selected);
        setColor("button is-success is-small");
        if (s) {
          addSensor(sensor);
          return;
        }

        setColor("");
        removeSensor(sensor);
      }}
    >
      {sensor.Name}
      {/* {name} */}
      {/* {sensor.Name} {name} */}
    </button>
  );
};

const SensorList = ({
  disabled,
  chunks,
  setResults,
}: {
  disabled: boolean;
  chunks: Status[][];
  setResults: React.Dispatch<React.SetStateAction<Status[]>>;
}) => {
  const [selectedList, setSelectedList] = React.useState<Status[]>([]);

  const addSensor = React.useCallback(
    (sensor: Status) => {
      if (selectedList.find((s) => s.Name === sensor.Name)) return;

      const r = [...selectedList, sensor];
      setSelectedList(r);
      setResults(r);
    },
    [selectedList]
  );

  const removeSensor = React.useCallback(
    (sensor: Status) => {
      const f = selectedList.filter((s) => s.Name !== sensor.Name);
      setSelectedList(f);
      setResults(f);
    },
    [selectedList]
  );

  const list = chunks.map((data, i) => {
    const row = data.map((sensor: Status) => {
      return (
        <td>
          {/* <div className="level-item" key={sensor.Name}> */}
          <SensorButton
            sensor={sensor}
            disabled={disabled}
            addSensor={addSensor}
            removeSensor={removeSensor}
            // key={sensor.Name}
          />
        </td>
      );
    });
    return (
      // <div className="level" key={i}>
      <tr>{row}</tr>
    );
  });
  return (
    <table className="table is-striped is-fullwidth is-bordered"> {list}</table>
  );
};

const SensorSelector = (props: Props) => {
  const [filter, setFilter] = React.useState("");
  const [filtered, setFiltered] = React.useState(props.sensors);
  const [chunks, setChunks] = React.useState([]);
  const [results, setResults] = React.useState([]);
  const [validationMessage, setValidationMessage] = React.useState("");
  const [canValidate, setCanValidate] = React.useState(false);

  React.useEffect(() => {
    const chunks = filterChunks(filtered);
    setChunks(chunks);
  }, []);

  // split list into chunks of 4

  const synchronize = () => {
    if (results.length <= 0) return;

    results.forEach((s) => {
      console.log(s);
    });

    const r = results.map((s) => s.Name);
    props.synchandler(r);
  };

  const filterSensors = (e: string) => {
    setFilter(e);
    const filtered = props.sensors.filter((s) =>
      s.Name.toLowerCase().includes(e.toLowerCase())
    );
    setFiltered(filtered);
    const chunks = filterChunks(filtered);
    setChunks(chunks);
  };

  const updateResults = (sensors: Status[]) => {
    setValidationMessage("");
    setCanValidate(false);
    setResults(sensors);
  };

  // turn results into options
  const getResults = () => {
    return results.map((s) => {
      return (
        <li>
          <strong>{s.Name}</strong>
        </li>
      );
    });
  };

  return (
    <>
      <div style={{ marginBottom: "10px" }}>
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <span className="tag is-info">Etape 1</span>
            </div>
            <div className="level-item">
              <span>
                Séléctionnez un ou plusieurs <strong>capteurs cible.s</strong>{" "}
                (type <strong>{props.label}</strong>)
              </span>
            </div>
          </div>
        </div>
        {/* <strong>Filtre</strong> : pour trouver plus facilement un capteur entrez
        son nom :
        <input
          style={{ marginLeft: "10px" }}
          type="text"
          value={filter}
          onChange={(e) => filterSensors(e.target.value)}
        /> */}
      </div>
      <SensorList
        setResults={updateResults}
        disabled={props.disabled}
        chunks={chunks}
      />
      {results.length > 0 ? (
        <div style={{ marginTop: "10px" }} className="box">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <span className="tag is-info">Etape 2</span>
              </div>
              <div className="level-item">
                <span>Confirmez votre sélection </span>
              </div>
            </div>
          </div>

          <div className="columns">
            <div className="column ">
              <label className="label">Liste</label>
              <div className="content">
                <ol>{getResults()}</ol>
              </div>
            </div>
            <div className="column is-two-thirds">
              {!canValidate ? (
                <div className="field">
                  <label className="label">
                    Tapez VALIDER dans le champs texte pour confirmer votre
                    sélection
                  </label>
                  <div className="control">
                    <input
                      className="input is-info"
                      type="text"
                      placeholder="tapez VALIDER pour confirmer la validation"
                      value={validationMessage}
                      onChange={(e) => {
                        setValidationMessage(e.target.value);
                        if (e.target.value.length > 0) {
                          if (
                            e.target.value.trim().toLocaleLowerCase() ==
                            "valider"
                          ) {
                            setCanValidate(true);
                          } else {
                            setCanValidate(false);
                          }
                        }
                      }}
                    />
                  </div>
                </div>
              ) : (
                <> </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {canValidate ? (
        <div style={{ marginTop: "10px" }} className="box">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <span className="tag is-info">Etape 3</span>
              </div>
              <div className="level-item">
                <span>Démarrer la copie des paramètres</span>
              </div>
            </div>
          </div>
          <div className="field">
            <label className="label">
              ⚠️‼️ Attention : la copie est <strong>définitive</strong>. ‼️⚠️
            </label>

            <button
              disabled={!canValidate}
              className="button is-warning"
              style={{ marginTop: "20px" }}
              onClick={() => synchronize()}
            >
              Appuyez ici pour effectuer la copie des paramètres de capteur vers
              les capteurs sélectionnés
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default hot(module)(SensorSelector);
