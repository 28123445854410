import { Union } from "../../fable-library.3.7.0/Types.js";
import { union_type, string_type, list_type, class_type } from "../../fable-library.3.7.0/Reflection.js";
import { Common_parseOptions, Common_GenericOptions__AddCaseName_1505, Common_GenericOptions__AddModifiers_5BB435D5, Common_GenericOptions__AddClass_Z721C83C5, Common_GenericOptions__AddProps_416C4D0B, Common_GenericOptions_Parse_9AE2F7C, Common_GenericOptions__ToReactElement_Z6D3CD4B7, Modifier_IModifier$reflection } from "../Common.fs.js";
import * as react from "react";
import { keyValueList } from "../../fable-library.3.7.0/MapUtil.js";

export class Level_Option extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Props", "is-mobile", "CustomClass", "Modifiers"];
    }
}

export function Level_Option$reflection() {
    return union_type("Fulma.Level.Level.Option", [], Level_Option, () => [[["Item", list_type(class_type("Fable.React.Props.IHTMLProp"))]], [], [["Item", string_type]], [["Item", list_type(Modifier_IModifier$reflection())]]]);
}

export class Item_Option extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Props", "has-text-centered", "CustomClass", "Modifiers"];
    }
}

export function Item_Option$reflection() {
    return union_type("Fulma.Level.Item.Option", [], Item_Option, () => [[["Item", list_type(class_type("Fable.React.Props.IHTMLProp"))]], [], [["Item", string_type]], [["Item", list_type(Modifier_IModifier$reflection())]]]);
}

export function level(options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, (result, option) => {
        switch (option.tag) {
            case 0: {
                return Common_GenericOptions__AddProps_416C4D0B(result, option.fields[0]);
            }
            case 2: {
                return Common_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            }
            case 3: {
                return Common_GenericOptions__AddModifiers_5BB435D5(result, option.fields[0]);
            }
            default: {
                return Common_GenericOptions__AddCaseName_1505(result, option);
            }
        }
    }, "level"), (props_1, children_1) => react.createElement("nav", keyValueList(props_1, 1), ...children_1), children);
}

export function left(options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, (result, option) => Common_parseOptions(result, option), "level-left"), (props, children_1) => react.createElement("div", keyValueList(props, 1), ...children_1), children);
}

export function right(options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, (result, option) => Common_parseOptions(result, option), "level-right"), (props, children_1) => react.createElement("div", keyValueList(props, 1), ...children_1), children);
}

export function item(options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, (result, option) => {
        switch (option.tag) {
            case 0: {
                return Common_GenericOptions__AddProps_416C4D0B(result, option.fields[0]);
            }
            case 2: {
                return Common_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            }
            case 3: {
                return Common_GenericOptions__AddModifiers_5BB435D5(result, option.fields[0]);
            }
            default: {
                return Common_GenericOptions__AddCaseName_1505(result, option);
            }
        }
    }, "level-item"), (props_1, children_1) => react.createElement("div", keyValueList(props_1, 1), ...children_1), children);
}

export function heading(options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, (result, option) => Common_parseOptions(result, option), "heading"), (props, children_1) => react.createElement("p", keyValueList(props, 1), ...children_1), children);
}

export function title(options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, (result, option) => Common_parseOptions(result, option), "title"), (props, children_1) => react.createElement("p", keyValueList(props, 1), ...children_1), children);
}

