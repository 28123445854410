import { hot } from "react-hot-loader";
import * as React from "react";

type Props = {
  name: string;
  title: string;
  isLoaded: boolean;
  loadSensor: (name: string) => void;
  unloadSensor: (name: string) => void;
  locked: boolean;
};

const Component = (props: Props) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [boxColor, setBoxColor] = React.useState(
    isLoading ? "has-background-success" : "has-background-light"
  );
  const [textColor, setTextColor] = React.useState(
    isLoading ? "has-text-white" : "has-text-dark"
  );

  React.useEffect(() => {
    setIsLoading(props.isLoaded);
    setBoxColor(isLoading ? "has-background-dark" : "has-background-light");
    setTextColor(isLoading ? "has-text-white" : "has-text-dark");
  });

  return (
    <div
      key={props.name}
      id={`sensor_${props.name}`}
      className="column is-2"
      style={{ cursor: "pointer" }}
      onClick={(e) => {
        if (props.locked) return;

        if (isLoading) {
          props.unloadSensor(props.name);
          setIsLoading(false);
          setBoxColor("has-background-light");
          setTextColor("has-text-dark");
          return;
        }

        props.loadSensor(props.name);
        setIsLoading(true);
        setBoxColor("has-background-success");
        setTextColor("has-text-white");
      }}
    >
      <div className={`box ${boxColor} ${textColor}`}>
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <div className="content is-size-6-widescreen">
                <p> {props.name}</p>
                <p>
                  {props.title
                    ? `(${props.title.substring(
                        0,
                        Math.min(20, props.title.length)
                      )}...)`
                    : ""}
                </p>
              </div>
            </div>
          </div>
          <div className="level-right">
            <div className="level-item">
              <span className="icon has-text-light is-large">
                <i className="fas fa-minus-circle fa-2x"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

type SimpleComponentProps = {
  name: string;
  title?: string;
  loadSensor: (name: string) => void;
};

const SimpleComponent = (props: SimpleComponentProps) => {
  const [boxColor, setBoxColor] = React.useState("has-background-light");
  const [textColor, setTextColor] = React.useState("has-text-dark");

  return (
    <div
      key={props.name}
      id={`sensor_${props.name}`}
      className="column is-2"
      style={{ cursor: "pointer" }}
      onClick={(e) => {
        props.loadSensor(props.name);
      }}
    >
      <div className={`box ${boxColor} ${textColor}`}>
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <div className="content ">
                <p> {props.name}</p>
                <p>
                  {props.title
                    ? `(${props.title.substring(
                        0,
                        Math.min(15, props.title.length)
                      )}...)`
                    : ""}
                </p>
              </div>
            </div>
          </div>
          <div className="level-right">
            <div className="level-item">
              <span className="icon has-text-dark is-large">
                <i className="fas fa-plus-circle fa-2x"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default hot(module)(Component);
export { SimpleComponent };
