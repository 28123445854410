import { Record, Union } from "../fable_modules/fable-library.3.7.0/Types.js";
import { record_type, option_type, list_type, union_type, string_type } from "../fable_modules/fable-library.3.7.0/Reflection.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { empty, ofArray, map, singleton, isEmpty } from "../fable_modules/fable-library.3.7.0/List.js";
import { Inside_Str, Inside_Heading_h1, Inside_Section } from "../GogoFulma.fs.js";
import { Option, column } from "../fable_modules/Fulma.2.16.0/Layouts/Column.fs.js";
import { DOMAttr } from "../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { Common_GenericOption, Modifier_IModifier, Color_IColor } from "../fable_modules/Fulma.2.16.0/Common.fs.js";
import { Option as Option_1, h2, h6 } from "../fable_modules/Fulma.2.16.0/Elements/Heading.fs.js";
import { map as map_1, toList } from "../fable_modules/fable-library.3.7.0/Seq.js";
import { columns } from "../fable_modules/Fulma.2.16.0/Layouts/Columns.fs.js";
import { Seq_split, UI_subButtonBar } from "../Utils.fs.js";
import { section } from "../fable_modules/Fulma.2.16.0/Layouts/Section.fs.js";
import { box$0027 } from "../fable_modules/Fulma.2.16.0/Elements/Box.fs.js";
import * as react from "react";

export class Types_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Load", "GetSensor"];
    }
}

export function Types_Msg$reflection() {
    return union_type("Page.Sensors.Types.Msg", [], Types_Msg, () => [[], [["Item", string_type]]]);
}

export class Types_Model extends Record {
    constructor(Data, Error$) {
        super();
        this.Data = Data;
        this.Error = Error$;
    }
}

export function Types_Model$reflection() {
    return record_type("Page.Sensors.Types.Model", [], Types_Model, () => [["Data", list_type(string_type)], ["Error", option_type(string_type)]]);
}

export function Types_initialModel(list) {
    return new Types_Model(list, void 0);
}

export function State_update(msg, model) {
    return [model, Cmd_none()];
}

export function View_root(model, dispatch) {
    if (isEmpty(model.Data)) {
        return Inside_Section(singleton(Inside_Heading_h1(singleton(Inside_Str("Aucun capteur n\u0027est pour l\u0027instant référencé sur votre compte.")))));
    }
    else {
        const modules = map((name) => column(ofArray([new Option(3, ofArray([["style", {
            cursor: "pointer",
        }], new DOMAttr(40, (_arg1) => {
            dispatch(new Types_Msg(1, name));
        })])), new Option(4, ofArray([new Modifier_IModifier(0, new Color_IColor(0)), new Modifier_IModifier(1, new Color_IColor(15))]))]), singleton(h6(empty())(singleton(name)))), model.Data);
        const list_2 = toList(map_1((arg00) => columns(empty(), toList(map_1((name_1) => column(singleton(new Option(3, ofArray([new DOMAttr(40, (_arg2) => {
            dispatch(new Types_Msg(1, name_1));
        }), ["style", {
            fontFamily: "Open Sans",
            cursor: "pointer",
        }]]))), singleton(UI_subButtonBar(name_1))), arg00))), Seq_split(10, model.Data)));
        return section(empty(), ofArray([box$0027(singleton(new Common_GenericOption(2, singleton(new Modifier_IModifier(0, new Color_IColor(1))))), singleton(h2(singleton(new Option_1(9, singleton(["style", {
            color: "#66C88E",
        }]))))(singleton("Mes capteurs")))), react.createElement("div", {}, ...list_2)]));
    }
}

