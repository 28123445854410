import * as jspdf from "jspdf";
import { some } from "./fable_modules/fable-library.3.7.0/Option.js";
import { ofArray, singleton, append, head as head_1, tail as tail_2, isEmpty } from "./fable_modules/fable-library.3.7.0/List.js";
import { length, head as head_2, tail as tail_3, filter, sum as sum_1, average, max as max_1, min as min_1, iterate, map, toList } from "./fable_modules/fable-library.3.7.0/Seq.js";
import { substring, toConsole, printf, toText, replace, split as split_3 } from "./fable_modules/fable-library.3.7.0/String.js";
import { Union } from "./fable_modules/fable-library.3.7.0/Types.js";
import { union_type, float64_type } from "./fable_modules/fable-library.3.7.0/Reflection.js";
import { stringHash, min as min_2, comparePrimitives, partialApply } from "./fable_modules/fable-library.3.7.0/Util.js";
import { localFormat } from "./fable_modules/Fable.Date.1.0.0/Date.Format.fs.js";
import { french } from "./fable_modules/Fable.Date.1.0.0/Date.Local.fs.js";
import { now } from "./fable_modules/fable-library.3.7.0/Date.js";
import { groupBy } from "./fable_modules/fable-library.3.7.0/Seq2.js";

export function PDF_create(y) {
    return [new jspdf(), y];
}

export function PDF_createLandscape(y) {
    return [new jspdf(some("landscape")), y];
}

export function PDF_addSplitText(text, x, width, height, data_0, data_1) {
    const data = [data_0, data_1];
    const addText = (currentLine_mut, doc_1_mut, ln_1_mut, tokens_mut) => {
        addText:
        while (true) {
            const currentLine = currentLine_mut, doc_1 = doc_1_mut, ln_1 = ln_1_mut, tokens = tokens_mut;
            if (isEmpty(tokens)) {
                if (currentLine.length > 0) {
                    const ln_3 = ln_1 + height;
                    return [doc_1.text(some(currentLine), some(x), some(ln_3)), ln_3];
                }
                else {
                    return [doc_1, ln_1];
                }
            }
            else {
                const tail = tail_2(tokens);
                const index = head_1(tokens);
                if ((currentLine.length + index.length) <= 140) {
                    currentLine_mut = (currentLine + index);
                    doc_1_mut = doc_1;
                    ln_1_mut = ln_1;
                    tokens_mut = tail;
                    continue addText;
                }
                else {
                    const ln_2 = ln_1 + height;
                    currentLine_mut = "";
                    doc_1_mut = doc_1.text(some(currentLine), some(x), some(ln_2));
                    ln_1_mut = ln_2;
                    tokens_mut = append(singleton(index), tail);
                    continue addText;
                }
            }
            break;
        }
    };
    const patternInput = addText("", data[0], data[1], toList(map((s) => (s + " "), split_3(replace(text, "\n", ""), [" "], null, 0))));
    return [patternInput[0], patternInput[1]];
}

export function PDF_addText(text, x, align, data_0, data_1) {
    const data = [data_0, data_1];
    if (text.trim().length > 0) {
        const ln = data[1];
        const doc = data[0];
        if (align === "left") {
            return [doc.text(some(text), some(x), some(ln)), ln];
        }
        else {
            const align_1 = align.toLocaleLowerCase();
            return [doc.text(some(text), some(x), some(ln), some(some(align_1))), ln];
        }
    }
    else {
        return data;
    }
}

export function PDF_setLineNumber(height, data_0, data_1) {
    return [data_0, height];
}

export function PDF_addPage(data_0, data_1) {
    const data = [data_0, data_1];
    return [data[0].addPage(), data[1]];
}

export function PDF_increaseLineNumber(height, data_0, data_1) {
    const data = [data_0, data_1];
    return [data[0], data[1] + height];
}

export function PDF_decreaseLineNumber(height, data_0, data_1) {
    const data = [data_0, data_1];
    return [data[0], data[1] - height];
}

export function PDF_output(data_0, data_1) {
    return data_0.output("blob");
}

export function PDF_saveTo(name, data_0, data_1) {
    data_0.save(name);
    return {};
}

export function PDF_prepareFonts(data_0, data_1) {
    const data = [data_0, data_1];
    return [data[0], data[1]];
}

export function PDF_setFont(name, data_0, data_1) {
    const data = [data_0, data_1];
    return [data[0].setFont(name), data[1]];
}

export function PDF_setFontSize(size, data_0, data_1) {
    const data = [data_0, data_1];
    return [data[0].setFontSize(size), data[1]];
}

export function PDF_setPage(number, data_0, data_1) {
    return data_0.setPage(number);
}

export function PDF_addImage(imgData, kind, x, y, data_0, data_1) {
    const data = [data_0, data_1];
    return [data[0].addImage(some(some(imgData)), some(some(kind)), x, y), data[1]];
}

export function PDF_addLogo(logo, x, y, data_0, data_1) {
    const tupledArg = [data_0, data_1];
    return PDF_addImage(logo, "JPEG", x, y, tupledArg[0], tupledArg[1]);
}

export function PDF_addLine(x1, y1, x2, y2, data_0, data_1) {
    const data = [data_0, data_1];
    const doc = data[0];
    doc.line(x1, y1, x2, y2);
    return [doc, data[1]];
}

export function PDF_underline(x1, x2, data_0, data_1) {
    const data = [data_0, data_1];
    const ln = data[1];
    const tupledArg = data;
    return PDF_addLine(x1, ln, x2, ln, tupledArg[0], tupledArg[1]);
}

export class PDF_ColorSpace extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Gray", "RGB", "CMYK"];
    }
}

export function PDF_ColorSpace$reflection() {
    return union_type("PDFWriter.PDF.ColorSpace", [], PDF_ColorSpace, () => [[["Item", float64_type]], [["Item1", float64_type], ["Item2", float64_type], ["Item3", float64_type]], [["Item1", float64_type], ["Item2", float64_type], ["Item3", float64_type], ["Item4", float64_type]]]);
}

export class PDF_RectKind extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Fill", "Draw", "FillAndDraw"];
    }
}

export function PDF_RectKind$reflection() {
    return union_type("PDFWriter.PDF.RectKind", [], PDF_RectKind, () => [[["Item", PDF_ColorSpace$reflection()]], [["Item", PDF_ColorSpace$reflection()]], [["Item1", PDF_ColorSpace$reflection()], ["Item2", PDF_ColorSpace$reflection()]]]);
}

export class PDF_Operation extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Fill", "Draw"];
    }
}

export function PDF_Operation$reflection() {
    return union_type("PDFWriter.PDF.Operation", [], PDF_Operation, () => [[], []]);
}

export function PDF_drawRect(x, width, height, kind, data_0, data_1) {
    const data = [data_0, data_1];
    const ln = data[1];
    const doc = data[0];
    const gray = (g, o, doc_1) => {
        const g_1 = toText(printf("%f"))(g);
        if (o.tag === 1) {
            return doc_1.setDrawColor(g_1);
        }
        else {
            return doc_1.setFillColor(g_1);
        }
    };
    const rgb = (r, g_2, b, o_1, doc_2) => {
        const r_1 = toText(printf("%f"))(r);
        const g_3 = toText(printf("%f"))(g_2);
        const b_1 = toText(printf("%f"))(b);
        if (o_1.tag === 1) {
            return doc_2.setDrawColor(r_1, g_3, b_1);
        }
        else {
            return doc_2.setFillColor(r_1, g_3, b_1);
        }
    };
    const cmyk = (c, m, y, k, o_2, doc_3) => {
        const c_1 = toText(printf("%f"))(c);
        const m_1 = toText(printf("%f"))(m);
        const y_1 = toText(printf("%f"))(y);
        const k_1 = toText(printf("%f"))(k);
        if (o_2.tag === 1) {
            return doc_3.setDrawColor(c_1, m_1, y_1, k_1);
        }
        else {
            return doc_3.setFillColor(c_1, m_1, y_1, k_1);
        }
    };
    const fill = (color, doc_4) => ((color.tag === 1) ? partialApply(1, rgb, [color.fields[0], color.fields[1], color.fields[2], new PDF_Operation(0)]) : ((color.tag === 2) ? partialApply(1, cmyk, [color.fields[0], color.fields[1], color.fields[2], color.fields[3], new PDF_Operation(0)]) : partialApply(1, gray, [color.fields[0], new PDF_Operation(0)])))(doc_4);
    const draw = (color_1, doc_5) => ((color_1.tag === 1) ? partialApply(1, rgb, [color_1.fields[0], color_1.fields[1], color_1.fields[2], new PDF_Operation(1)]) : ((color_1.tag === 2) ? partialApply(1, cmyk, [color_1.fields[0], color_1.fields[1], color_1.fields[2], color_1.fields[3], new PDF_Operation(1)]) : partialApply(1, gray, [color_1.fields[0], new PDF_Operation(1)])))(doc_5);
    const patternInput = (kind.tag === 1) ? [draw(kind.fields[0], doc), void 0] : ((kind.tag === 2) ? [draw(kind.fields[1], fill(kind.fields[0], doc)), "FD"] : [fill(kind.fields[0], doc), "F"]);
    const modifier = patternInput[1];
    const doc_6 = patternInput[0];
    if (modifier == null) {
        return [doc_6.rect(x, ln, width, height), ln];
    }
    else {
        const m_4 = modifier;
        return [doc_6.rect(x, ln, width, height, m_4), ln];
    }
}

function PDF_inRange(value) {
    if (value >= 0) {
        return value <= 1;
    }
    else {
        return false;
    }
}

export function PDF_setFillGreyColor(g, data_0, data_1) {
    const data = [data_0, data_1];
    if (!PDF_inRange(g)) {
        throw (new Error("Please provide a value between 0. and 1.0"));
    }
    else {
        return [data[0].setFillColor(g), data[1]];
    }
}

export function PDF_setFillRGBColor(r, g, b, data_0, data_1) {
    const data = [data_0, data_1];
    if (((!PDF_inRange(r)) ? true : (!PDF_inRange(g))) ? true : (!PDF_inRange(b))) {
        throw (new Error("Please provide a value between 0. and 1.0"));
    }
    else {
        return [data[0].setFillColor(r, g, b), data[1]];
    }
}

export function PDF_setFillCMYKColor(c, m, y, k, data_0, data_1) {
    const data = [data_0, data_1];
    if ((((!PDF_inRange(c)) ? true : (!PDF_inRange(m))) ? true : (!PDF_inRange(y))) ? true : (!PDF_inRange(k))) {
        throw (new Error("Please provide a value between 0. and 1.0"));
    }
    else {
        return [data[0].setFillColor(c, m, y, k), data[1]];
    }
}

export function PDF_setDrawGreyColor(g, data_0, data_1) {
    const data = [data_0, data_1];
    if (!PDF_inRange(g)) {
        throw (new Error("Please provide a value between 0. and 1.0"));
    }
    else {
        return [data[0].setDrawColor(g), data[1]];
    }
}

export function PDF_setDrawRGBColor(r, g, b, data_0, data_1) {
    const data = [data_0, data_1];
    if (((!PDF_inRange(r)) ? true : (!PDF_inRange(g))) ? true : (!PDF_inRange(b))) {
        throw (new Error("Please provide a value between 0. and 1.0"));
    }
    else {
        return [data[0].setDrawColor(r, g, b), data[1]];
    }
}

export function PDF_setDrawCMYKColor(c, m, y, k, data_0, data_1) {
    const data = [data_0, data_1];
    if ((((!PDF_inRange(c)) ? true : (!PDF_inRange(m))) ? true : (!PDF_inRange(y))) ? true : (!PDF_inRange(k))) {
        throw (new Error("Please provide a value between 0. and 1.0"));
    }
    else {
        return [data[0].setDrawColor(c, m, y, k), data[1]];
    }
}

export function PDF_splitTextToSize(text, size, data_0, data_1) {
    const data = [data_0, data_1];
    return [data[0].splitTextToSize(text, size), data[1]];
}

export function PDF_Utils_getTextWidth(text, data_0, data_1) {
    return data_0.getTextWidth(text);
}

export function prepare(csvData, pdfName, realName, downloadFile) {
    const d = localFormat(french, "dd/MM/yyyy HH:mm", now());
    const date_1 = toText(printf("Date d\u0027émission du document: %s - Document strictement informatif sans valeur légale."))(d);
    const atStartingPosition = 15;
    const fontSize = 5;
    const lineHeight = fontSize * 0.5;
    const marginLeft = 10;
    const center = 105;
    const rightAligncenter = center + 40;
    const addPageNumber = (number, label, date_2, data) => {
        let tupledArg_3;
        let tupledArg_2;
        let tupledArg_1;
        const tupledArg = data;
        tupledArg_1 = PDF_setLineNumber(290, tupledArg[0], tupledArg[1]);
        tupledArg_2 = PDF_addText(toText(printf("Page %i"))(number), 200, "right", tupledArg_1[0], tupledArg_1[1]);
        tupledArg_3 = PDF_addText(label, marginLeft, "left", tupledArg_2[0], tupledArg_2[1]);
        return PDF_addText(date_2, center, "center", tupledArg_3[0], tupledArg_3[1]);
    };
    const addHeader = (name, data_1) => {
        let tupledArg_6;
        let tupledArg_5;
        const tupledArg_4 = data_1;
        tupledArg_5 = PDF_setFontSize(14, tupledArg_4[0], tupledArg_4[1]);
        tupledArg_6 = PDF_increaseLineNumber(lineHeight * 2, tupledArg_5[0], tupledArg_5[1]);
        return PDF_addText(name, center, "center", tupledArg_6[0], tupledArg_6[1]);
    };
    const printFields = (fields_mut, i_mut, lastSpacing_mut, data_3_mut) => {
        let x_1, tupledArg_13, tupledArg_12, tupledArg_11, tupledArg_10;
        printFields:
        while (true) {
            const fields = fields_mut, i = i_mut, lastSpacing = lastSpacing_mut, data_3 = data_3_mut;
            const lineFactor = 1.3;
            if (!isEmpty(fields)) {
                const mleft = marginLeft;
                const width_1 = (i === 0) ? 25 : 23.5;
                fields_mut = tail_2(fields);
                i_mut = (i + 1);
                lastSpacing_mut = (lastSpacing + width_1);
                data_3_mut = ((x_1 = (mleft + lastSpacing), (tupledArg_13 = ((tupledArg_12 = ((tupledArg_11 = ((tupledArg_10 = data_3, PDF_drawRect(mleft + lastSpacing, width_1, lineHeight * lineFactor, new PDF_RectKind(1, new PDF_ColorSpace(0, 0)), tupledArg_10[0], tupledArg_10[1]))), PDF_increaseLineNumber((lineHeight * lineFactor) * 0.7, tupledArg_11[0], tupledArg_11[1]))), PDF_addText(head_1(fields), x_1 + 2.7, "left", tupledArg_12[0], tupledArg_12[1]))), PDF_decreaseLineNumber((lineHeight * lineFactor) * 0.7, tupledArg_13[0], tupledArg_13[1]))));
                continue printFields;
            }
            else {
                return data_3;
            }
            break;
        }
    };
    const printPDF = (doc_mut, actions_mut, pageNumber_mut, totalHeight_mut) => {
        printPDF:
        while (true) {
            const doc = doc_mut, actions = actions_mut, pageNumber = pageNumber_mut, totalHeight = totalHeight_mut;
            if (isEmpty(actions)) {
                return addPageNumber(pageNumber, realName, date_1, doc);
            }
            else {
                const tail_1 = tail_2(actions);
                let patternInput;
                const tupledArg_15 = head_1(actions)(doc);
                patternInput = PDF_setFontSize(fontSize, tupledArg_15[0], tupledArg_15[1]);
                const ln = patternInput[1];
                if (ln > 275) {
                    let patternInput_1;
                    let tupledArg_19;
                    let tupledArg_18;
                    let tupledArg_17;
                    const tupledArg_16 = addPageNumber(pageNumber, realName, date_1, doc);
                    tupledArg_17 = PDF_addPage(tupledArg_16[0], tupledArg_16[1]);
                    tupledArg_18 = PDF_setLineNumber(atStartingPosition, tupledArg_17[0], tupledArg_17[1]);
                    tupledArg_19 = PDF_setFontSize(fontSize, tupledArg_18[0], tupledArg_18[1]);
                    patternInput_1 = PDF_increaseLineNumber(lineHeight * 6, tupledArg_19[0], tupledArg_19[1]);
                    const ln_1 = patternInput_1[1];
                    doc_mut = [patternInput_1[0], ln_1];
                    actions_mut = tail_1;
                    pageNumber_mut = (pageNumber + 1);
                    totalHeight_mut = ln_1;
                    continue printPDF;
                }
                else {
                    doc_mut = [patternInput[0], ln];
                    actions_mut = tail_1;
                    pageNumber_mut = pageNumber;
                    totalHeight_mut = (totalHeight + ln);
                    continue printPDF;
                }
            }
            break;
        }
    };
    const start = PDF_create(atStartingPosition);
    toConsole(printf("Starting PDF generation... %s"))(csvData);
    let rows_3;
    const statsRows = toList(map((row_2) => {
        const rdata_1 = row_2[1];
        const key_1 = row_2[0];
        iterate((d_1) => {
            const value_5 = d_1[4];
            toConsole(printf("Parsing: %s"))(value_5);
            try {
                const t_1 = parseFloat(replace(value_5, ",", "."));
                toConsole(printf("\u003eok"));
            }
            catch (e_3) {
                toConsole(printf("Can\u0027t parse: %s"))(value_5);
            }
        }, rdata_1);
        const values = map((d_2) => {
            try {
                return parseFloat(replace(d_2[4], ",", "."));
            }
            catch (e_4) {
                return 0;
            }
        }, rdata_1);
        const min = min_1(values, {
            Compare: (x_4, y_1) => comparePrimitives(x_4, y_1),
        });
        const max = max_1(values, {
            Compare: (x_5, y_2) => comparePrimitives(x_5, y_2),
        });
        const avg = average(values, {
            GetZero: () => 0,
            Add: (x_7, y_3) => (x_7 + y_3),
            DivideByInt: (x_6, i_1) => (x_6 / i_1),
        });
        const sum = sum_1(values, {
            GetZero: () => 0,
            Add: (x_8, y_4) => (x_8 + y_4),
        });
        const sensorName_6 = substring(key_1, 0, min_2((x_9, y_5) => comparePrimitives(x_9, y_5), 18, key_1.length));
        const rdata = ofArray([toText(printf("%s..."))(sensorName_6), toText(printf("%.2f"))(min), toText(printf("%.2f"))(max), toText(printf("%.2f"))(avg), toText(printf("%.2f"))(sum)]);
        return (data_9) => {
            let tupledArg_21;
            return printFields(rdata, 0, 0, (tupledArg_21 = data_9, PDF_increaseLineNumber(lineHeight * 1.3, tupledArg_21[0], tupledArg_21[1])));
        };
    }, groupBy((e_2) => e_2[3], filter((e_1) => (!(e_1[3].indexOf("Non Util") >= 0)), map((row_1) => {
        const split = split_3(row_1, [";"], null, 0);
        return [split[0], split[1], split[2], split[3], split[4]];
    }, tail_3(split_3(csvData, ["\n"], null, 0)))), {
        Equals: (x_3, y) => (x_3 === y),
        GetHashCode: (x_3) => stringHash(x_3),
    })));
    const rdata_3 = groupBy((e_6) => e_6[0], filter((e_5) => (!(e_5[2].indexOf("Non Util") >= 0)), map((row_3) => {
        const split_1 = split_3(row_3, [";"], null, 0);
        return [split_1[0], split_1[1], split_1[2], split_1[3], split_1[4]];
    }, tail_3(split_3(csvData, ["\n"], null, 0)))), {
        Equals: (x_10, y_6) => (x_10 === y_6),
        GetHashCode: (x_10) => stringHash(x_10),
    });
    rows_3 = append(singleton((data_8) => {
        let tupledArg_20;
        return printFields(ofArray(["Sonde", "Valeur Min", "Valeur Max", "Moyenne", "Cumul"]), 0, 0, (tupledArg_20 = data_8, PDF_increaseLineNumber(lineHeight * 1.3, tupledArg_20[0], tupledArg_20[1])));
    }), append(statsRows, append(singleton((data_11) => {
        const tupledArg_23 = data_11;
        return PDF_increaseLineNumber(lineHeight * 1.3, tupledArg_23[0], tupledArg_23[1]);
    }), append(singleton((data_10) => {
        let tupledArg_22;
        const rdata_4 = head_2(rdata_3)[1];
        const l_2 = length(rdata_4) | 0;
        return printFields(append(singleton("Date"), toList(map((e_7) => {
            const probe_8 = e_7[3];
            const sensorName_10 = substring(probe_8, 0, min_2((x_11, y_7) => comparePrimitives(x_11, y_7), 18, probe_8.length));
            return toText(printf("%s..."))(sensorName_10);
        }, rdata_4))), 0, 0, (tupledArg_22 = data_10, PDF_increaseLineNumber(lineHeight * 1.3, tupledArg_22[0], tupledArg_22[1])));
    }), toList(map((row_4) => {
        const rdata_5 = row_4[1];
        const l_5 = length(rdata_5) | 0;
        return (data_7) => {
            let tupledArg_14;
            const rows = rdata_5;
            const fields_1 = toList(map((e) => toText(printf("%s"))(e[4]), rows));
            return printFields(append(singleton(head_2(rows)[1]), fields_1), 0, 0, (tupledArg_14 = data_7, PDF_increaseLineNumber(lineHeight * 1.3, tupledArg_14[0], tupledArg_14[1])));
        };
    }, rdata_3))))));
    const split_2 = split_3(split_3(pdfName, ["."], null, 0)[0], ["_"], null, 0);
    let sensorName_11;
    const arg10_12 = split_2[0];
    sensorName_11 = toText(printf("Capteur : %s"))(arg10_12);
    let name_2;
    const arg10_13 = replace(split_2[1], "-", " ");
    name_2 = toText(printf("Nom : %s"))(arg10_13);
    let period;
    const arg10_14 = split_2[2];
    period = toText(printf("Période : %s"))(arg10_14);
    const actions_1 = append(ofArray([partialApply(1, addHeader, [sensorName_11]), partialApply(1, addHeader, [name_2]), partialApply(1, addHeader, [period])]), rows_3);
    if (downloadFile) {
        const tupledArg_24 = printPDF(start, actions_1, 1, 0);
        return PDF_saveTo(pdfName, tupledArg_24[0], tupledArg_24[1]);
    }
    else {
        const tupledArg_25 = printPDF(start, actions_1, 1, 0);
        return PDF_output(tupledArg_25[0], tupledArg_25[1]);
    }
}

export function prepareFlat(csvData, pdfName, realName, id, from, ending, downloadFile) {
    const d = localFormat(french, "dd/MM/yyyy HH:mm", now());
    const date_1 = toText(printf("Date d\u0027émission du document: %s - Document strictement informatif sans valeur légale."))(d);
    const atStartingPosition = 15;
    const fontSize = 5;
    const lineHeight = fontSize * 0.5;
    const marginLeft = 10;
    const center = 105;
    const rightAligncenter = center + 40;
    const addPageNumber = (number, label, date_2, data) => {
        let tupledArg_3;
        let tupledArg_2;
        let tupledArg_1;
        const tupledArg = data;
        tupledArg_1 = PDF_setLineNumber(290, tupledArg[0], tupledArg[1]);
        tupledArg_2 = PDF_addText(toText(printf("Page %i"))(number), 200, "right", tupledArg_1[0], tupledArg_1[1]);
        tupledArg_3 = PDF_addText(label, marginLeft, "left", tupledArg_2[0], tupledArg_2[1]);
        return PDF_addText(date_2, center, "center", tupledArg_3[0], tupledArg_3[1]);
    };
    const addHeader = (name, data_1) => {
        let tupledArg_6;
        let tupledArg_5;
        const tupledArg_4 = data_1;
        tupledArg_5 = PDF_setFontSize(14, tupledArg_4[0], tupledArg_4[1]);
        tupledArg_6 = PDF_increaseLineNumber(lineHeight * 2, tupledArg_5[0], tupledArg_5[1]);
        return PDF_addText(name, center, "center", tupledArg_6[0], tupledArg_6[1]);
    };
    const printFields = (fields_mut, i_mut, lastSpacing_mut, data_3_mut) => {
        let x_1, tupledArg_13, tupledArg_12, tupledArg_11, tupledArg_10;
        printFields:
        while (true) {
            const fields = fields_mut, i = i_mut, lastSpacing = lastSpacing_mut, data_3 = data_3_mut;
            const lineFactor = 1.3;
            if (!isEmpty(fields)) {
                const mleft = marginLeft;
                const width_1 = (i === 0) ? 25 : 23.5;
                fields_mut = tail_2(fields);
                i_mut = (i + 1);
                lastSpacing_mut = (lastSpacing + width_1);
                data_3_mut = ((x_1 = (mleft + lastSpacing), (tupledArg_13 = ((tupledArg_12 = ((tupledArg_11 = ((tupledArg_10 = data_3, PDF_drawRect(mleft + lastSpacing, width_1, lineHeight * lineFactor, new PDF_RectKind(1, new PDF_ColorSpace(0, 0)), tupledArg_10[0], tupledArg_10[1]))), PDF_increaseLineNumber((lineHeight * lineFactor) * 0.7, tupledArg_11[0], tupledArg_11[1]))), PDF_addText(head_1(fields), x_1 + 2.7, "left", tupledArg_12[0], tupledArg_12[1]))), PDF_decreaseLineNumber((lineHeight * lineFactor) * 0.7, tupledArg_13[0], tupledArg_13[1]))));
                continue printFields;
            }
            else {
                return data_3;
            }
            break;
        }
    };
    const printPDF = (doc_mut, actions_mut, pageNumber_mut, totalHeight_mut) => {
        printPDF:
        while (true) {
            const doc = doc_mut, actions = actions_mut, pageNumber = pageNumber_mut, totalHeight = totalHeight_mut;
            if (isEmpty(actions)) {
                return addPageNumber(pageNumber, realName, date_1, doc);
            }
            else {
                const tail_1 = tail_2(actions);
                let patternInput;
                const tupledArg_15 = head_1(actions)(doc);
                patternInput = PDF_setFontSize(fontSize, tupledArg_15[0], tupledArg_15[1]);
                const ln = patternInput[1];
                if (ln > 190) {
                    let patternInput_1;
                    let tupledArg_19;
                    let tupledArg_18;
                    let tupledArg_17;
                    const tupledArg_16 = addPageNumber(pageNumber, realName, date_1, doc);
                    tupledArg_17 = PDF_addPage(tupledArg_16[0], tupledArg_16[1]);
                    tupledArg_18 = PDF_setLineNumber(atStartingPosition, tupledArg_17[0], tupledArg_17[1]);
                    tupledArg_19 = PDF_setFontSize(fontSize, tupledArg_18[0], tupledArg_18[1]);
                    patternInput_1 = PDF_increaseLineNumber(lineHeight * 6, tupledArg_19[0], tupledArg_19[1]);
                    const ln_1 = patternInput_1[1];
                    doc_mut = [patternInput_1[0], ln_1];
                    actions_mut = tail_1;
                    pageNumber_mut = (pageNumber + 1);
                    totalHeight_mut = ln_1;
                    continue printPDF;
                }
                else {
                    doc_mut = [patternInput[0], ln];
                    actions_mut = tail_1;
                    pageNumber_mut = pageNumber;
                    totalHeight_mut = (totalHeight + ln);
                    continue printPDF;
                }
            }
            break;
        }
    };
    const start = PDF_createLandscape(atStartingPosition);
    toConsole(printf("Starting PDF generation... %s"))(csvData);
    const csvSplit = split_3(csvData, ["\n"], null, 0);
    const rows_1 = toList(map((row) => {
        const split = split_3(row, [";"], null, 0);
        const rows = split.slice(2, split.length);
        return (data_7) => {
            let tupledArg_14;
            return printFields(toList(map((value) => {
                if (value.length > 20) {
                    const arg10_2 = substring(value, 0, 20) + "...";
                    return toText(printf("%s"))(arg10_2);
                }
                else {
                    return toText(printf("%s"))(value);
                }
            }, rows)), 0, 0, (tupledArg_14 = data_7, PDF_increaseLineNumber(lineHeight * 1.3, tupledArg_14[0], tupledArg_14[1])));
        };
    }, csvSplit));
    const name_1 = split_3(pdfName, ["."], null, 0)[0];
    const firstDate = split_3(csvSplit[1], [";"], null, 0);
    let startDate;
    const arg10_5 = firstDate[2];
    startDate = toText(printf("%s"))(arg10_5);
    const lastDate = split_3(csvSplit[csvSplit.length - 1], [";"], null, 0);
    let endDate;
    const arg10_6 = lastDate[2];
    endDate = toText(printf("%s"))(arg10_6);
    const period = toText(printf("Période : %s - %s"))(startDate)(endDate);
    const actions_1 = append(ofArray([partialApply(1, addHeader, [id]), partialApply(1, addHeader, [name_1]), partialApply(1, addHeader, [period])]), rows_1);
    if (downloadFile) {
        const tupledArg_20 = printPDF(start, actions_1, 1, 0);
        return PDF_saveTo(pdfName, tupledArg_20[0], tupledArg_20[1]);
    }
    else {
        const tupledArg_21 = printPDF(start, actions_1, 1, 0);
        return PDF_output(tupledArg_21[0], tupledArg_21[1]);
    }
}

