import { Record, Union } from "../fable_modules/fable-library.3.7.0/Types.js";
import { record_type, option_type, list_type, string_type, union_type } from "../fable_modules/fable-library.3.7.0/Reflection.js";
import { body, hero } from "../fable_modules/Fulma.2.16.0/Layouts/Hero.fs.js";
import { ofArray, singleton, empty } from "../fable_modules/fable-library.3.7.0/List.js";
import { UI_buttonBar } from "../Utils.fs.js";
import { columns } from "../fable_modules/Fulma.2.16.0/Layouts/Columns.fs.js";
import { Props$1, view } from "../Components/OptionBox.fs.js";
import { Fa_IconOption } from "../fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";

export class Types_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Back"];
    }
}

export function Types_Msg$reflection() {
    return union_type("Page.AppOptions.Types.Msg", [], Types_Msg, () => [[]]);
}

export class Types_Model extends Record {
    constructor(Options) {
        super();
        this.Options = Options;
    }
}

export function Types_Model$reflection() {
    return record_type("Page.AppOptions.Types.Model", [], Types_Model, () => [["Options", option_type(list_type(string_type))]]);
}

export function Types_initialModel(options) {
    return new Types_Model(options);
}

export function View_root(model, dispatch) {
    return hero(empty(), singleton(body(empty(), ofArray([UI_buttonBar("Mes options", (_arg1) => {
        dispatch(new Types_Msg(0));
    }), columns(empty(), singleton(view(new Props$1("Ajout d\u0027utilisateur", new Fa_IconOption(11, "fas fa-user-friends"), "Ajoutez un compte utilisateur pour vos capteurs.", "je veux que quelqu\u0027un d\u0027autre puisse consulter et paramétrer mes capteurs depuis un compte différent.", model.Options, "ADDUSER"))))]))));
}

export function State_update(msg, model) {
    return [model, Cmd_none()];
}

