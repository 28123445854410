import { toString, Record, Union } from "../../fable_modules/fable-library.3.7.0/Types.js";
import { record_type, option_type, tuple_type, bool_type, list_type, string_type, class_type, union_type } from "../../fable_modules/fable-library.3.7.0/Reflection.js";
import { FrontVersionNumber, Auth_ForgetPasswordResponse$reflection, Auth_Response$reflection } from "../../Shared.fs.js";
import { Auth_sendMagicLink, Auth_signIn, FetchError$reflection } from "../../Fetch.fs.js";
import { FSharpResult$2 } from "../../fable_modules/fable-library.3.7.0/Choice.js";
import { LoginKind$reflection, Route$reflection } from "../../Types.fs.js";
import { UI_createField, FormChecker_isOk, FormChecker_validateField, FormChecker_registerChecks, FieldValidator_Check, FieldValidator_Required, FieldValidator_Check$reflection, FieldValidator_Required$reflection } from "../../Utils.fs.js";
import { FSharpMap__get_Item, ofSeq } from "../../fable_modules/fable-library.3.7.0/Map.js";
import { singleton, isEmpty, ofArray, empty } from "../../fable_modules/fable-library.3.7.0/List.js";
import { comparePrimitives } from "../../fable_modules/fable-library.3.7.0/Util.js";
import { Cmd_batch, Cmd_none, Cmd_OfFunc_result, Cmd_OfPromise_either } from "../../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { printf, toText } from "../../fable_modules/fable-library.3.7.0/String.js";
import { Option, button as button_2 } from "../../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import { Common_GenericOption, Modifier_IModifier, Screen, Color_IColor } from "../../fable_modules/Fulma.2.16.0/Common.fs.js";
import { HTMLAttr, DOMAttr } from "../../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { Inside_Tag, Inside_Form_form, Inside_Section, Inside_Str, Inside_Notification, Inside_Span, Inside_Icon } from "../../GogoFulma.fs.js";
import { Fa_IconOption } from "../../fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { columns } from "../../fable_modules/Fulma.2.16.0/Layouts/Columns.fs.js";
import { Option as Option_1, ISize, column } from "../../fable_modules/Fulma.2.16.0/Layouts/Column.fs.js";
import { box$0027 } from "../../fable_modules/Fulma.2.16.0/Elements/Box.fs.js";
import * as react from "react";
import { keyValueList } from "../../fable_modules/fable-library.3.7.0/MapUtil.js";
import { Option as Option_2, h3 } from "../../fable_modules/Fulma.2.16.0/Elements/Heading.fs.js";
import { Option as Option_3, content } from "../../fable_modules/Fulma.2.16.0/Elements/Content.fs.js";
import { IInputType } from "../../fable_modules/Fulma.2.16.0/Elements/Form/Input.fs.js";

export class Types_LoginPage extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Standard", "Magic", "MagicSent"];
    }
}

export function Types_LoginPage$reflection() {
    return union_type("Page.Auth.Login.Types.LoginPage", [], Types_LoginPage, () => [[], [], []]);
}

export class Types_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Load", "Login", "OnLogin", "OnError", "Route", "CheckField", "OnSendMagicLink", "ValidateField", "EnableAction", "SaveToken", "ChangePage", "SendMagicLink"];
    }
}

export function Types_Msg$reflection() {
    return union_type("Page.Auth.Login.Types.Msg", [], Types_Msg, () => [[], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Auth_Response$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Auth_Response$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", class_type("System.Exception")]], [["Item", Route$reflection()]], [["Item1", string_type], ["Item2", string_type]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Auth_ForgetPasswordResponse$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Auth_ForgetPasswordResponse$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", string_type]], [], [["Item", Auth_Response$reflection()]], [["Item", Types_LoginPage$reflection()]], []]);
}

export class Types_Model extends Record {
    constructor(Fields, CanLogin, LoginError, LoginKind, LoginPage) {
        super();
        this.Fields = Fields;
        this.CanLogin = CanLogin;
        this.LoginError = LoginError;
        this.LoginKind = LoginKind;
        this.LoginPage = LoginPage;
    }
}

export function Types_Model$reflection() {
    return record_type("Page.Auth.Login.Types.Model", [], Types_Model, () => [["Fields", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, tuple_type(FieldValidator_Required$reflection(), string_type, list_type(string_type), bool_type, list_type(FieldValidator_Check$reflection()))])], ["CanLogin", bool_type], ["LoginError", option_type(string_type)], ["LoginKind", LoginKind$reflection()], ["LoginPage", Types_LoginPage$reflection()]]);
}

export function Types_initialModel(lk) {
    return new Types_Model(ofSeq([["email", [new FieldValidator_Required(0), "", empty(), false, ofArray([new FieldValidator_Check(0), new FieldValidator_Check(1, 3)])]], ["password", [new FieldValidator_Required(0), "", empty(), false, ofArray([new FieldValidator_Check(0), new FieldValidator_Check(1, 3)])]]], {
        Compare: (x, y) => comparePrimitives(x, y),
    }), false, void 0, lk, new Types_LoginPage(0));
}

export function Commands_getResult(handler, response) {
    return handler(response);
}

export function Commands_getError(handler, response) {
    return handler(response);
}

export function Commands_SignIn(email, password) {
    return Cmd_OfPromise_either((tupledArg) => Auth_signIn(tupledArg[0], tupledArg[1]), [email, password], (response) => Commands_getResult((arg0) => (new Types_Msg(2, arg0)), response), (response_1) => Commands_getError((arg0_1) => (new Types_Msg(3, arg0_1)), response_1));
}

export function Commands_SendMagicLink(email) {
    return Cmd_OfPromise_either((login) => Auth_sendMagicLink(login), email, (response) => Commands_getResult((arg0) => (new Types_Msg(6, arg0)), response), (response_1) => Commands_getError((arg0_1) => (new Types_Msg(3, arg0_1)), response_1));
}

export function State_update(msg, model) {
    const getData = (field) => {
        const patternInput = FSharpMap__get_Item(model.Fields, toString(field));
        return [patternInput[1], patternInput[2]];
    };
    switch (msg.tag) {
        case 5: {
            const whatField = msg.fields[1];
            return [new Types_Model(FormChecker_registerChecks(msg.fields[0], whatField, model.Fields), model.CanLogin, void 0, model.LoginKind, model.LoginPage), Cmd_OfFunc_result(new Types_Msg(7, whatField))];
        }
        case 7: {
            return [new Types_Model(FormChecker_validateField(msg.fields[0], model.Fields), model.CanLogin, model.LoginError, model.LoginKind, model.LoginPage), Cmd_OfFunc_result(new Types_Msg(8))];
        }
        case 8: {
            return [new Types_Model(model.Fields, FormChecker_isOk(model.Fields), model.LoginError, model.LoginKind, model.LoginPage), empty()];
        }
        case 2: {
            const response = msg.fields[0];
            if (response.tag === 1) {
                const err = response.fields[0];
                const updated_2 = FormChecker_registerChecks("", "password", FormChecker_registerChecks("", "email", model.Fields));
                switch (err.tag) {
                    case 1: {
                        return [new Types_Model(updated_2, false, err.fields[0], model.LoginKind, model.LoginPage), Cmd_none()];
                    }
                    case 2: {
                        return [new Types_Model(updated_2, false, err.fields[0], model.LoginKind, model.LoginPage), Cmd_none()];
                    }
                    default: {
                        return [new Types_Model(updated_2, false, toText(printf("Erreur code %i : %s"))(err.fields[0])(err.fields[1]), model.LoginKind, model.LoginPage), Cmd_none()];
                    }
                }
            }
            else {
                return [model, Cmd_OfFunc_result(new Types_Msg(9, response.fields[0]))];
            }
        }
        case 11: {
            let patternInput_2;
            const patternInput_1 = FSharpMap__get_Item(model.Fields, toString("email"));
            patternInput_2 = [patternInput_1[1], patternInput_1[2]];
            const email = patternInput_2[0];
            if ((email.trim().length > 0) && isEmpty(patternInput_2[1])) {
                return [new Types_Model(model.Fields, model.CanLogin, model.LoginError, model.LoginKind, new Types_LoginPage(2)), Commands_SendMagicLink(email)];
            }
            else {
                return [new Types_Model(FormChecker_registerChecks("", "password", FormChecker_registerChecks("", "email", model.Fields)), false, "Veuillez saisir une adresse email valide. Merci.", model.LoginKind, model.LoginPage), Cmd_none()];
            }
        }
        case 10: {
            return [new Types_Model(model.Fields, model.CanLogin, model.LoginError, model.LoginKind, msg.fields[0]), Cmd_none()];
        }
        case 3: {
            return [new Types_Model(model.Fields, model.CanLogin, "Authentification impossible: le serveur est déconnecté.", model.LoginKind, model.LoginPage), Cmd_none()];
        }
        case 1: {
            if (model.CanLogin) {
                const patternInput_3 = getData("email");
                const patternInput_4 = getData("password");
                return [model, Cmd_batch(ofArray([Cmd_OfFunc_result(new Types_Msg(0)), Commands_SignIn(patternInput_3[0], patternInput_4[0])]))];
            }
            else {
                return [model, Cmd_none()];
            }
        }
        default: {
            return [model, Cmd_none()];
        }
    }
}

export function View_root(model, dispatch) {
    let props_12, children_6, props_24, children_10, props_30, children_14, props, children, props_4, children_4;
    const matchValue = model.LoginPage;
    switch (matchValue.tag) {
        case 2: {
            let logo_1;
            const matchValue_3 = model.LoginKind;
            switch (matchValue_3.tag) {
                case 1: {
                    logo_1 = "logo_mg.png";
                    break;
                }
                case 2: {
                    logo_1 = "logo_occion.png";
                    break;
                }
                default: {
                    logo_1 = "logo_mg.png";
                }
            }
            const magicLink = button_2(ofArray([new Option(0, new Color_IColor(1)), new Option(8), new Option(4), new Option(17, ofArray([new DOMAttr(40, (_arg3) => {
                dispatch(new Types_Msg(10, new Types_LoginPage(1)));
            }), ["style", {
                cursor: "pointer",
                width: "100%",
            }]]))]), ofArray([Inside_Icon(new Fa_IconOption(11, "fas fa-id-badge")), Inside_Span("Retour aux options de connexion")]));
            const children_8 = [columns(empty(), ofArray([column(singleton(new Option_1(0, new Screen(1), new ISize(1))), singleton("")), column(singleton(new Option_1(0, new Screen(1), new ISize(1))), ofArray([box$0027(singleton(new Common_GenericOption(2, singleton(new Modifier_IModifier(0, new Color_IColor(1))))), singleton((props_12 = [new HTMLAttr(149, logo_1), ["style", {
                width: "30%",
            }]], react.createElement("img", keyValueList(props_12, 1))))), box$0027(singleton(new Common_GenericOption(2, singleton(new Modifier_IModifier(0, new Color_IColor(1))))), ofArray([h3(singleton(new Option_2(10, singleton(new Modifier_IModifier(1, new Color_IColor(4))))))(singleton("Connexion par lien d\u0027accès direct")), content(singleton(new Option_3(3, singleton(new Modifier_IModifier(1, new Color_IColor(3))))), singleton((children_6 = ["Merci d\u0027avoir choisi la connexion par lien d\u0027accès direct !", react.createElement("br", {}), react.createElement("br", {}), "Si l\u0027adresse email que vous avez entrée est celle reliée à votre compte, vous recevrez bientôt un message par email qui contiendra un lien pour vous connecter sans mot de passe.", react.createElement("br", {}), "Merci de vérifier votre boîte email dès à présent."], react.createElement("p", {}, ...children_6))))])), magicLink])), column(singleton(new Option_1(0, new Screen(1), new ISize(1))), singleton(""))]))];
            return react.createElement("div", {}, ...children_8);
        }
        case 1: {
            const button_1 = button_2(ofArray([new Option(0, new Color_IColor(2)), new Option(8), new Option(17, ofArray([new DOMAttr(40, (_arg4) => {
                dispatch(new Types_Msg(10, new Types_LoginPage(0)));
            }), ["style", {
                cursor: "pointer",
                width: "100%",
            }]]))]), ofArray([Inside_Icon(new Fa_IconOption(11, "fas fa-passport")), Inside_Span("Connexion avec votre mot de passe")]));
            let errors_3;
            const matchValue_4 = model.LoginError;
            errors_3 = ((matchValue_4 == null) ? "" : Inside_Notification(new Color_IColor(8), singleton((props_24 = [["style", {
                fontFamily: "Open Sans",
            }]], (children_10 = [Inside_Str(matchValue_4)], react.createElement("p", keyValueList(props_24, 1), ...children_10))))));
            let fields_1;
            let patternInput_4;
            const patternInput_3 = FSharpMap__get_Item(model.Fields, toString("email"));
            patternInput_4 = [patternInput_3[1], patternInput_3[2]];
            const emailField_1 = UI_createField(1, "current-email", new IInputType(8), "Votre identifiant ou adresse email (pour lien magique)", patternInput_4[0], patternInput_4[1], new Fa_IconOption(11, "far fa-envelope"), (inputValue_2) => {
                dispatch(new Types_Msg(5, inputValue_2, "email"));
            });
            fields_1 = Inside_Section(singleton(Inside_Form_form([h3(singleton(new Option_2(10, singleton(new Modifier_IModifier(1, new Color_IColor(2))))))(singleton("Gestion de vos capteurs")), content(singleton(new Option_3(3, singleton(new Modifier_IModifier(1, new Color_IColor(2))))), ofArray(["Veuillez indiquer l\u0027adresse email / l\u0027dentifiant utilisée pour votre compte", react.createElement("br", {}), "et choisissez une option de connexion"])), emailField_1])));
            let logo_2;
            const matchValue_5 = model.LoginKind;
            switch (matchValue_5.tag) {
                case 1: {
                    logo_2 = "logo_mg.png";
                    break;
                }
                case 2: {
                    logo_2 = "logo_occion.png";
                    break;
                }
                default: {
                    logo_2 = "logo.png";
                }
            }
            let version_1;
            const props_28 = [["style", {
                position: "absolute",
                position: "absolute",
                top: 0,
                right: 10,
            }]];
            const children_12 = [Inside_Tag(new Color_IColor(5), FrontVersionNumber)];
            version_1 = react.createElement("div", keyValueList(props_28, 1), ...children_12);
            const forgetPassword_1 = button_2(ofArray([new Option(0, new Color_IColor(4)), new Option(8), new Option(20, singleton(new Modifier_IModifier(1, new Color_IColor(1)))), new Option(17, ofArray([new DOMAttr(40, (_arg5) => {
                dispatch(new Types_Msg(11));
            }), ["style", {
                cursor: "pointer",
                width: "100%",
            }]]))]), ofArray([Inside_Icon(new Fa_IconOption(11, "fas fa-magic")), Inside_Span("Recevoir un lien d\u0027accès direct par email")]));
            return Inside_Section(ofArray([columns(empty(), ofArray([column(singleton(new Option_1(0, new Screen(1), new ISize(1))), singleton("")), column(singleton(new Option_1(0, new Screen(1), new ISize(1))), ofArray([box$0027(singleton(new Common_GenericOption(2, singleton(new Modifier_IModifier(0, new Color_IColor(1))))), singleton((props_30 = [new HTMLAttr(149, logo_2), ["style", {
                width: "30%",
            }]], react.createElement("img", keyValueList(props_30, 1))))), box$0027(singleton(new Common_GenericOption(2, singleton(new Modifier_IModifier(0, new Color_IColor(1))))), ofArray([fields_1, errors_3, (children_14 = [button_1, react.createElement("br", {}), react.createElement("br", {}), forgetPassword_1], react.createElement("p", {}, ...children_14))]))])), column(singleton(new Option_1(0, new Screen(1), new ISize(1))), singleton(""))])), version_1]));
        }
        default: {
            const button = button_2(ofArray([new Option(4), new Option(8), new Option(0, new Color_IColor(4)), new Option(17, ofArray([new DOMAttr(40, (_arg1) => {
                dispatch(new Types_Msg(1));
            }), ["style", {
                cursor: "pointer",
                width: "100%",
            }]]))]), ofArray([Inside_Icon(new Fa_IconOption(11, "fas fa-sign-in-alt")), Inside_Span("Connexion")]));
            let errors;
            const matchValue_1 = model.LoginError;
            errors = ((matchValue_1 == null) ? "" : Inside_Notification(new Color_IColor(8), singleton((props = [["style", {
                fontFamily: "Open Sans",
            }]], (children = [Inside_Str(matchValue_1)], react.createElement("p", keyValueList(props, 1), ...children))))));
            let fields;
            const mkInput = (tabIndex, autoComplete, inputType, label, currentValue, errors_1, icon, onChange) => UI_createField(tabIndex, autoComplete, inputType, label, currentValue, errors_1, icon, onChange);
            const getData = (field) => {
                const patternInput = FSharpMap__get_Item(model.Fields, toString(field));
                return [patternInput[1], patternInput[2]];
            };
            const patternInput_1 = getData("email");
            const patternInput_2 = getData("password");
            fields = Inside_Section(singleton(Inside_Form_form([mkInput(1, "current-email", new IInputType(8), "Votre identifiant", patternInput_1[0], patternInput_1[1], new Fa_IconOption(11, "far fa-envelope"), (inputValue) => {
                dispatch(new Types_Msg(5, inputValue, "email"));
            }), mkInput(2, "current-password", new IInputType(1), "Mot de passe", patternInput_2[0], patternInput_2[1], new Fa_IconOption(11, "fas fa-unlock-alt"), (inputValue_1) => {
                dispatch(new Types_Msg(5, inputValue_1, "password"));
            })])));
            let logo;
            const matchValue_2 = model.LoginKind;
            switch (matchValue_2.tag) {
                case 1: {
                    logo = "logo_mg.png";
                    break;
                }
                case 2: {
                    logo = "logo_occion.png";
                    break;
                }
                default: {
                    logo = "logo_mg.png";
                }
            }
            let version;
            const props_2 = [["style", {
                position: "absolute",
                position: "absolute",
                top: 0,
                right: 0,
            }]];
            const children_2 = [Inside_Tag(new Color_IColor(5), FrontVersionNumber)];
            version = react.createElement("div", keyValueList(props_2, 1), ...children_2);
            const forgetPassword = button_2(ofArray([new Option(0, new Color_IColor(4)), new Option(8), new Option(20, singleton(new Modifier_IModifier(1, new Color_IColor(1)))), new Option(17, ofArray([new DOMAttr(40, (_arg2) => {
                dispatch(new Types_Msg(11));
            }), ["style", {
                cursor: "pointer",
                width: "100%",
            }]]))]), ofArray([Inside_Icon(new Fa_IconOption(11, "fas fa-magic")), Inside_Span("Recevoir un lien d\u0027accès direct par email")]));
            return Inside_Section(ofArray([columns(empty(), ofArray([column(singleton(new Option_1(0, new Screen(1), new ISize(1))), singleton("")), column(singleton(new Option_1(0, new Screen(1), new ISize(1))), ofArray([box$0027(singleton(new Common_GenericOption(2, singleton(new Modifier_IModifier(0, new Color_IColor(1))))), singleton((props_4 = [new HTMLAttr(149, logo), ["style", {
                width: "30%",
            }]], react.createElement("img", keyValueList(props_4, 1))))), box$0027(singleton(new Common_GenericOption(2, ofArray([new Modifier_IModifier(0, new Color_IColor(1)), new Modifier_IModifier(1, new Color_IColor(2))]))), ofArray([fields, errors, (children_4 = [button, react.createElement("br", {}), react.createElement("br", {}), forgetPassword], react.createElement("p", {}, ...children_4))]))])), column(singleton(new Option_1(0, new Screen(1), new ISize(1))), singleton(""))])), version]));
        }
    }
}

