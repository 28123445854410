import { Record } from "./fable_modules/fable-library.3.7.0/Types.js";
import { option_type, list_type, bool_type, record_type, array_type, anonRecord_type, string_type } from "./fable_modules/fable-library.3.7.0/Reflection.js";
import { list as list_2, object, Auto_generateBoxedEncoder_Z20B7B430, toString } from "./fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.7.0/Choice.js";
import { Auto_generateBoxedDecoder_79988AEF, fromString } from "./fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { uncurry } from "./fable_modules/fable-library.3.7.0/Util.js";
import { Tag$reflection } from "./Shared.fs.js";
import { empty, map } from "./fable_modules/fable-library.3.7.0/List.js";

export function Token_exists() {
    const matchValue = sessionStorage.getItem("iotpool");
    if (matchValue == null) {
        return false;
    }
    else {
        return true;
    }
}

export function Token_set(token) {
    sessionStorage.setItem("iotpool", token);
}

export function Token_get() {
    const matchValue = sessionStorage.getItem("iotpool");
    if (matchValue == null) {
        throw (new Error("no token available"));
    }
    else {
        return matchValue;
    }
}

export function Token_load() {
    const matchValue = sessionStorage.getItem("iotpool");
    if (matchValue == null) {
        return "invalid token";
    }
    else {
        return matchValue;
    }
}

export function Token_delete() {
    sessionStorage.removeItem("iotpool");
}

export function User_exists() {
    const matchValue = sessionStorage.getItem("iotpool-user");
    if (matchValue == null) {
        return false;
    }
    else {
        return true;
    }
}

export function User_set(user) {
    sessionStorage.setItem("iotpool-user", user);
}

export function User_get() {
    const matchValue = sessionStorage.getItem("iotpool-user");
    if (matchValue == null) {
        throw (new Error("no userId available"));
    }
    else {
        return matchValue;
    }
}

export function User_load() {
    const matchValue = sessionStorage.getItem("iotpool-user");
    if (matchValue == null) {
        return "invalid userId";
    }
    else {
        return matchValue;
    }
}

export function User_delete() {
    sessionStorage.removeItem("iotpool-user");
}

export class SelectedSensors_SelectedSensors extends Record {
    constructor(Sensors) {
        super();
        this.Sensors = Sensors;
    }
}

export function SelectedSensors_SelectedSensors$reflection() {
    return record_type("SessionStorage.SelectedSensors.SelectedSensors", [], SelectedSensors_SelectedSensors, () => [["Sensors", array_type(anonRecord_type(["label", string_type], ["value", string_type]))]]);
}

export function SelectedSensors_exists() {
    const matchValue = sessionStorage.getItem("iotpool-selected-sensors");
    if (matchValue == null) {
        return false;
    }
    else {
        return true;
    }
}

export function SelectedSensors_set(selectedSensors) {
    const encoded = toString(0, Auto_generateBoxedEncoder_Z20B7B430(SelectedSensors_SelectedSensors$reflection(), void 0, void 0, void 0)(selectedSensors));
    sessionStorage.setItem("iotpool-selected-sensors", encoded);
}

export function SelectedSensors_get() {
    const matchValue = sessionStorage.getItem("iotpool-selected-sensors");
    if (matchValue == null) {
        return new FSharpResult$2(1, "no selected sensors available");
    }
    else {
        const v = matchValue;
        return fromString(uncurry(2, Auto_generateBoxedDecoder_79988AEF(SelectedSensors_SelectedSensors$reflection(), void 0, void 0)), v);
    }
}

export function SelectedSensors_load() {
    const matchValue = sessionStorage.getItem("iotpool-selected-sensors");
    if (matchValue == null) {
        throw (new Error("invalid selected sensors"));
    }
    else {
        const selectedSensors = matchValue;
        const decoded = fromString(uncurry(2, Auto_generateBoxedDecoder_79988AEF(SelectedSensors_SelectedSensors$reflection(), void 0, void 0)), selectedSensors);
        if (decoded.tag === 0) {
            return decoded.fields[0];
        }
        else {
            throw (new Error("invalid selected sensors"));
        }
    }
}

export function SelectedSensors_delete() {
    sessionStorage.removeItem("iotpool-selected-sensors");
}

export class SelectedTags_SelectedTags extends Record {
    constructor(Tags) {
        super();
        this.Tags = Tags;
    }
}

export function SelectedTags_SelectedTags$reflection() {
    return record_type("SessionStorage.SelectedTags.SelectedTags", [], SelectedTags_SelectedTags, () => [["Tags", array_type(anonRecord_type(["label", string_type], ["value", string_type]))]]);
}

export function SelectedTags_exists() {
    const matchValue = sessionStorage.getItem("iotpool-selected-tags");
    if (matchValue == null) {
        return false;
    }
    else {
        return true;
    }
}

export function SelectedTags_set(selectedTags) {
    const encoded = toString(0, Auto_generateBoxedEncoder_Z20B7B430(SelectedTags_SelectedTags$reflection(), void 0, void 0, void 0)(selectedTags));
    sessionStorage.setItem("iotpool-selected-tags", encoded);
}

export function SelectedTags_get() {
    const matchValue = sessionStorage.getItem("iotpool-selected-tags");
    if (matchValue == null) {
        return new FSharpResult$2(1, "no selected tags available");
    }
    else {
        const v = matchValue;
        return fromString(uncurry(2, Auto_generateBoxedDecoder_79988AEF(SelectedTags_SelectedTags$reflection(), void 0, void 0)), v);
    }
}

export function SelectedTags_load() {
    const matchValue = sessionStorage.getItem("iotpool-selected-tags");
    if (matchValue == null) {
        throw (new Error("invalid selected tags"));
    }
    else {
        const selectedTags = matchValue;
        const decoded = fromString(uncurry(2, Auto_generateBoxedDecoder_79988AEF(SelectedTags_SelectedTags$reflection(), void 0, void 0)), selectedTags);
        if (decoded.tag === 0) {
            return decoded.fields[0];
        }
        else {
            throw (new Error("invalid selected tags"));
        }
    }
}

export function SelectedTags_delete() {
    sessionStorage.removeItem("iotpool-selected-tags");
}

export class SelectedKinds_SelectedKinds extends Record {
    constructor(Kinds) {
        super();
        this.Kinds = Kinds;
    }
}

export function SelectedKinds_SelectedKinds$reflection() {
    return record_type("SessionStorage.SelectedKinds.SelectedKinds", [], SelectedKinds_SelectedKinds, () => [["Kinds", array_type(anonRecord_type(["label", string_type], ["value", string_type]))]]);
}

export function SelectedKinds_exists() {
    const matchValue = sessionStorage.getItem("iotpool-selected-kinds");
    if (matchValue == null) {
        return false;
    }
    else {
        return true;
    }
}

export function SelectedKinds_set(selectedKinds) {
    const encoded = toString(0, Auto_generateBoxedEncoder_Z20B7B430(SelectedKinds_SelectedKinds$reflection(), void 0, void 0, void 0)(selectedKinds));
    sessionStorage.setItem("iotpool-selected-kinds", encoded);
}

export function SelectedKinds_get() {
    const matchValue = sessionStorage.getItem("iotpool-selected-kinds");
    if (matchValue == null) {
        return new FSharpResult$2(1, "no selected kinds available");
    }
    else {
        const v = matchValue;
        return fromString(uncurry(2, Auto_generateBoxedDecoder_79988AEF(SelectedKinds_SelectedKinds$reflection(), void 0, void 0)), v);
    }
}

export function SelectedKinds_load() {
    const matchValue = sessionStorage.getItem("iotpool-selected-kinds");
    if (matchValue == null) {
        throw (new Error("invalid selected kinds"));
    }
    else {
        const selectedKinds = matchValue;
        const decoded = fromString(uncurry(2, Auto_generateBoxedDecoder_79988AEF(SelectedKinds_SelectedKinds$reflection(), void 0, void 0)), selectedKinds);
        if (decoded.tag === 0) {
            return decoded.fields[0];
        }
        else {
            throw (new Error("invalid selected kinds"));
        }
    }
}

export function SelectedKinds_delete() {
    sessionStorage.removeItem("iotpool-selected-kinds");
}

export class SelectDisplayMode_SelectDisplayMode extends Record {
    constructor(DisplayMode) {
        super();
        this.DisplayMode = DisplayMode;
    }
}

export function SelectDisplayMode_SelectDisplayMode$reflection() {
    return record_type("SessionStorage.SelectDisplayMode.SelectDisplayMode", [], SelectDisplayMode_SelectDisplayMode, () => [["DisplayMode", string_type]]);
}

export function SelectDisplayMode_exists() {
    const matchValue = sessionStorage.getItem("iotpool-select-display-mode");
    if (matchValue == null) {
        return false;
    }
    else {
        return true;
    }
}

export function SelectDisplayMode_set(selectDisplayMode) {
    const encoded = toString(0, Auto_generateBoxedEncoder_Z20B7B430(SelectDisplayMode_SelectDisplayMode$reflection(), void 0, void 0, void 0)(selectDisplayMode));
    sessionStorage.setItem("iotpool-select-display-mode", encoded);
}

export function SelectDisplayMode_get() {
    const matchValue = sessionStorage.getItem("iotpool-select-display-mode");
    if (matchValue == null) {
        return new FSharpResult$2(1, "no select display mode available");
    }
    else {
        const v = matchValue;
        return fromString(uncurry(2, Auto_generateBoxedDecoder_79988AEF(SelectDisplayMode_SelectDisplayMode$reflection(), void 0, void 0)), v);
    }
}

export function SelectDisplayMode_load() {
    const matchValue = sessionStorage.getItem("iotpool-select-display-mode");
    if (matchValue == null) {
        throw (new Error("invalid select display mode"));
    }
    else {
        const selectDisplayMode = matchValue;
        const decoded = fromString(uncurry(2, Auto_generateBoxedDecoder_79988AEF(SelectDisplayMode_SelectDisplayMode$reflection(), void 0, void 0)), selectDisplayMode);
        if (decoded.tag === 0) {
            return decoded.fields[0];
        }
        else {
            throw (new Error("invalid select display mode"));
        }
    }
}

export function SelectDisplayMode_delete() {
    sessionStorage.removeItem("iotpool-select-display-mode");
}

export class Props_Props extends Record {
    constructor(StatusTableMode, Tags) {
        super();
        this.StatusTableMode = StatusTableMode;
        this.Tags = Tags;
    }
}

export function Props_Props$reflection() {
    return record_type("SessionStorage.Props.Props", [], Props_Props, () => [["StatusTableMode", bool_type], ["Tags", option_type(list_type(Tag$reflection()))]]);
}

export function Props_exists() {
    const matchValue = sessionStorage.getItem("iotpool-props");
    if (matchValue == null) {
        return false;
    }
    else {
        return true;
    }
}

export function Props_set(props) {
    let matchValue;
    const encoded = toString(0, object([["statusTableMode", props.StatusTableMode], ["tags", (matchValue = props.Tags, (matchValue != null) ? list_2(map((tag) => object([["label", tag.Label], ["sensors", list_2(map((value_1) => value_1, tag.Sensors))]]), matchValue)) : list_2(empty()))]]));
    sessionStorage.setItem("iotpool-props", encoded);
}

export function Props_get() {
    const matchValue = sessionStorage.getItem("iotpool-props");
    if (matchValue == null) {
        throw (new Error("no token available"));
    }
    else {
        const token = matchValue;
        return fromString(uncurry(2, Auto_generateBoxedDecoder_79988AEF(Props_Props$reflection(), void 0, void 0)), token);
    }
}

export function Props_load() {
    const matchValue = sessionStorage.getItem("iotpool-props");
    if (matchValue == null) {
        throw (new Error("invalid props"));
    }
    else {
        const props = matchValue;
        const decoded = fromString(uncurry(2, Auto_generateBoxedDecoder_79988AEF(Props_Props$reflection(), void 0, void 0)), props);
        if (decoded.tag === 0) {
            return decoded.fields[0];
        }
        else {
            throw (new Error("invalid props"));
        }
    }
}

export function Props_delete() {
    sessionStorage.removeItem("iotpool-props");
}

