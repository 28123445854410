import { Record } from "../fable_modules/fable-library.3.7.0/Types.js";
import { record_type, option_type, list_type, string_type } from "../fable_modules/fable-library.3.7.0/Reflection.js";
import { Fa_IconOption, Fa_ISize, Fa_span, Fa_IconOption$reflection } from "../fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { column } from "../fable_modules/Fulma.2.16.0/Layouts/Column.fs.js";
import { isEmpty, filter, singleton, ofArray, empty as empty_1 } from "../fable_modules/fable-library.3.7.0/List.js";
import { box$0027 } from "../fable_modules/Fulma.2.16.0/Elements/Box.fs.js";
import { TextSize_Option, Screen, Common_GenericOption, Modifier_IModifier, Color_IColor } from "../fable_modules/Fulma.2.16.0/Common.fs.js";
import { content, left, media } from "../fable_modules/Fulma.2.16.0/Components/Media.fs.js";
import { Option, h3 } from "../fable_modules/Fulma.2.16.0/Elements/Heading.fs.js";
import { content as content_1 } from "../fable_modules/Fulma.2.16.0/Elements/Content.fs.js";
import * as react from "react";
import { Option as Option_1, notification } from "../fable_modules/Fulma.2.16.0/Elements/Notification.fs.js";
import { printf, toText } from "../fable_modules/fable-library.3.7.0/String.js";
import { Item_Option, item, left as left_1, Level_Option, level } from "../fable_modules/Fulma.2.16.0/Layouts/Level.fs.js";
import { equals } from "../fable_modules/fable-library.3.7.0/Util.js";

export class Props$1 extends Record {
    constructor(Title, Icon, Description, Example, Options, TestedOption) {
        super();
        this.Title = Title;
        this.Icon = Icon;
        this.Description = Description;
        this.Example = Example;
        this.Options = Options;
        this.TestedOption = TestedOption;
    }
}

export function Props$1$reflection(gen0) {
    return record_type("Components.OptionBox.Props`1", [gen0], Props$1, () => [["Title", string_type], ["Icon", Fa_IconOption$reflection()], ["Description", string_type], ["Example", string_type], ["Options", option_type(list_type(gen0))], ["TestedOption", gen0]]);
}

export function view(props) {
    let which, empty, matchValue;
    return column(empty_1(), singleton(box$0027(singleton(new Common_GenericOption(2, ofArray([new Modifier_IModifier(0, new Color_IColor(1)), new Modifier_IModifier(1, new Color_IColor(2))]))), singleton(media(empty_1(), ofArray([left(singleton(new Common_GenericOption(1, singleton(["style", {
        color: "#66C88E",
    }]))), singleton(Fa_span(ofArray([props.Icon, new Fa_IconOption(0, new Fa_ISize(4))]), []))), content(empty_1(), ofArray([h3(singleton(new Option(9, singleton(["style", {
        color: "#66C88E",
    }]))))(singleton(props.Title)), content_1(empty_1(), singleton(react.createElement("p", {}, props.Description))), notification(singleton(new Option_1(0, new Color_IColor(0))), singleton(toText(printf("Exemple: %s"))(props.Example))), (which = props.TestedOption, (empty = ((which_1) => notification(singleton(new Option_1(0, new Color_IColor(0))), singleton(level(singleton(new Level_Option(3, singleton(new Modifier_IModifier(1, new Color_IColor(7))))), ofArray([left_1(empty_1(), singleton(Fa_span(ofArray([new Fa_IconOption(11, "fas fa-shopping-cart"), new Fa_IconOption(0, new Fa_ISize(3))]), []))), item(singleton(new Item_Option(3, ofArray([new Modifier_IModifier(3, new Screen(1), new TextSize_Option(3)), new Modifier_IModifier(3, new Screen(2), new TextSize_Option(5))]))), singleton(react.createElement("strong", {}, "Activer l\u0027option ? Contactez votre fournisseur.")))]))))), (matchValue = props.Options, (matchValue != null) ? (isEmpty(filter((opt) => equals(opt, which), matchValue)) ? empty(which) : notification(singleton(new Option_1(0, new Color_IColor(5))), singleton(level(empty_1(), ofArray([left_1(empty_1(), singleton(Fa_span(ofArray([new Fa_IconOption(11, "fas fa-check-circle"), new Fa_IconOption(0, new Fa_ISize(3))]), []))), item(singleton(new Item_Option(3, ofArray([new Modifier_IModifier(3, new Screen(1), new TextSize_Option(3)), new Modifier_IModifier(3, new Screen(2), new TextSize_Option(5))]))), singleton(react.createElement("strong", {}, "Option activée !")))]))))) : empty(which))))]))]))))));
}

