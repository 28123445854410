import * as toastr$002Emin from "toastr/build/toastr.min.css";
import { Record } from "../fable-library.3.7.0/Types.js";
import { record_type, option_type, lambda_type, unit_type, class_type, string_type } from "../fable-library.3.7.0/Reflection.js";
import { clear, remove, options as options_1, warning, info, error, success } from "toastr";
import { singleton } from "../fable-library.3.7.0/List.js";


export class Toastr_ToastrMsg$1 extends Record {
    constructor(Message, Title, Options, Dispatcher) {
        super();
        this.Message = Message;
        this.Title = Title;
        this.Options = Options;
        this.Dispatcher = Dispatcher;
    }
}

export function Toastr_ToastrMsg$1$reflection(gen0) {
    return record_type("Elmish.Toastr.Toastr.ToastrMsg`1", [gen0], Toastr_ToastrMsg$1, () => [["Message", string_type], ["Title", string_type], ["Options", class_type("Elmish.Toastr.ToastrOptions")], ["Dispatcher", option_type(lambda_type(gen0, unit_type))]]);
}

export function Toastr_defaultMsg() {
    return new Toastr_ToastrMsg$1("", "", {}, void 0);
}

const Toastr_successToastWithTitle = success;

const Toastr_errorToastWithTitle = error;

const Toastr_infoToastWithTitle = info;

const Toastr_warningToastWithTitle = warning;

export function Toastr_message(msg) {
    const inputRecord = Toastr_defaultMsg();
    return new Toastr_ToastrMsg$1(msg, inputRecord.Title, inputRecord.Options, inputRecord.Dispatcher);
}

export function Toastr_title(title, msg) {
    return new Toastr_ToastrMsg$1(msg.Message, title, msg.Options, msg.Dispatcher);
}

export function Toastr_timeout(timeout, msg) {
    msg.Options.timeOut=timeout;
    return msg;
}

export function Toastr_position(pos, msg) {
    msg.Options.positionClass=pos;
    return msg;
}

export function Toastr_extendedTimout(t, msg) {
    msg.Options.extendedTimeOut=t;
    return msg;
}

export function Toastr_onClick(nextMsg, msg) {
    msg.Options.onclick = (() => {
        const matchValue = msg.Dispatcher;
        if (matchValue == null) {
        }
        else {
            matchValue(nextMsg);
        }
    });
    return msg;
}

export function Toastr_onShown(nextMsg, msg) {
    msg.Options.onShown = (() => {
        const matchValue = msg.Dispatcher;
        if (matchValue == null) {
        }
        else {
            matchValue(nextMsg);
        }
    });
    return msg;
}

export function Toastr_tapToDismiss(msg) {
    msg.Options.tapToDismiss = true;
    return msg;
}

export function Toastr_onHidden(nextMsg, msg) {
    msg.Options.onHidden = (() => {
        const matchValue = msg.Dispatcher;
        if (matchValue == null) {
        }
        else {
            matchValue(nextMsg);
        }
    });
    return msg;
}

export function Toastr_showCloseButton(msg) {
    msg.Options.closeButton = true;
    return msg;
}

export function Toastr_withProgressBar(msg) {
    msg.Options.progressBar = true;
    return msg;
}

export function Toastr_closeButtonClicked(nextMsg, msg) {
    msg.Options.onCloseClick = (() => {
        const matchValue = msg.Dispatcher;
        if (matchValue == null) {
        }
        else {
            matchValue(nextMsg);
        }
    });
    return msg;
}

export function Toastr_hideEasing(e, msg) {
    msg.Options.hideEasing = e;
    return msg;
}

let Toastr_options = options_1;

export function Toastr_overrideOptions(opts) {
    Toastr_options = (Object.assign({}, Toastr_options, opts));
}

const Toastr_remove = remove;

const Toastr_clear = clear;

export function Toastr_clearAll() {
    return singleton((_arg1) => {
        Toastr_clear();
    });
}

export function Toastr_removeAll() {
    return singleton((_arg1) => {
        Toastr_remove();
    });
}

export function Toastr_success(msg) {
    return singleton((dispatch) => {
        msg.Dispatcher = dispatch;
        Toastr_successToastWithTitle(msg.Message, msg.Title, msg.Options);
    });
}

export function Toastr_error(msg) {
    return singleton((dispatch) => {
        msg.Dispatcher = dispatch;
        Toastr_errorToastWithTitle(msg.Message, msg.Title, msg.Options);
    });
}

export function Toastr_info(msg) {
    return singleton((dispatch) => {
        msg.Dispatcher = dispatch;
        Toastr_infoToastWithTitle(msg.Message, msg.Title, msg.Options);
    });
}

export function Toastr_warning(msg) {
    return singleton((dispatch) => {
        msg.Dispatcher = dispatch;
        Toastr_warningToastWithTitle(msg.Message, msg.Title, msg.Options);
    });
}

