import { Record, Union } from "./fable_modules/fable-library.3.7.0/Types.js";
import { record_type, list_type, tuple_type, string_type, union_type } from "./fable_modules/fable-library.3.7.0/Reflection.js";
import { head as head_1, singleton, ofArray, empty } from "./fable_modules/fable-library.3.7.0/List.js";

export class Kind extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Bug", "Info", "Hardware", "Upgrade"];
    }
}

export function Kind$reflection() {
    return union_type("Version.Kind", [], Kind, () => [[], [], [], []]);
}

export class Change extends Record {
    constructor(Number$, Changes) {
        super();
        this.Number = Number$;
        this.Changes = Changes;
    }
}

export function Change$reflection() {
    return record_type("Version.Change", [], Change, () => [["Number", string_type], ["Changes", list_type(tuple_type(Kind$reflection(), string_type))]]);
}

export const ChangeLog = ofArray([new Change("1.1.1", empty()), new Change("1.1.0", empty()), new Change("1.0.9", empty()), new Change("1.0.8", ofArray([[new Kind(3), "Admin"], [new Kind(3), "Rssi"], [new Kind(3), "Batteries"]])), new Change("1.0.7", singleton([new Kind(3), "Etat du parc"])), new Change("1.0.6", singleton([new Kind(3), "Page alarmes"])), new Change("1.0.5", ofArray([[new Kind(0), "Conflit à la sauvegarde"], [new Kind(3), "Page capteur"]])), new Change("1.0.4", ofArray([[new Kind(3), "Menu responsive"], [new Kind(3), "Page capteur responsive"]])), new Change("1.0.3", ofArray([[new Kind(3), "Login"], [new Kind(3), "Graphiques"]])), new Change("1.0.2", ofArray([[new Kind(3), "Liste des Capteurs"], [new Kind(3), "Graphiques des capteurs"]])), new Change("1.0.1", singleton([new Kind(1), "Work in progress"]))]);

export function v() {
    return head_1(ChangeLog).Number;
}

