import * as React from "react";

const Test = (props) => {
  return (
    <>
      <div>{props.msg}</div>
    </>
  );
};

export default Test;
