import { Record, Union } from "../fable_modules/fable-library.3.7.0/Types.js";
import { float64_type, record_type, bool_type, list_type, option_type, class_type, string_type, union_type } from "../fable_modules/fable-library.3.7.0/Reflection.js";
import { DownloadTimePeriod, Route, Route$reflection } from "../Types.fs.js";
import { DataList$reflection, View$reflection } from "../Shared.fs.js";
import { Public_view, FetchError$reflection } from "../Fetch.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.0/Choice.js";
import { Cmd_OfFunc_result, Cmd_none, Cmd_OfPromise_either } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { Option as Option_3, columns } from "../fable_modules/Fulma.2.16.0/Layouts/Columns.fs.js";
import { append, toArray, map as map_1, ofArray, singleton, empty } from "../fable_modules/fable-library.3.7.0/List.js";
import { last as last_2, map, toList } from "../fable_modules/fable-library.3.7.0/Seq.js";
import { localFormat } from "../fable_modules/Fable.Date.1.0.0/Date.Format.fs.js";
import { french } from "../fable_modules/Fable.Date.1.0.0/Date.Local.fs.js";
import { toLocalTime } from "../fable_modules/fable-library.3.7.0/Date.js";
import { printf, toText } from "../fable_modules/fable-library.3.7.0/String.js";
import { Option as Option_4, ISize, column } from "../fable_modules/Fulma.2.16.0/Layouts/Column.fs.js";
import { Option as Option_1, notification } from "../fable_modules/Fulma.2.16.0/Elements/Notification.fs.js";
import { Option as Option_2, h2, h1, h5 } from "../fable_modules/Fulma.2.16.0/Elements/Heading.fs.js";
import { Option, section } from "../fable_modules/Fulma.2.16.0/Layouts/Section.fs.js";
import { TextAlignment_Option, TextSize_Option, Color_IColor, Modifier_IModifier, Screen } from "../fable_modules/Fulma.2.16.0/Common.fs.js";
import { graph } from "../RechartsHelper.fs.js";
import * as react from "react";
import { LineChart, Tooltip, YAxis, XAxis, CartesianGrid, Line, ResponsiveContainer } from "recharts";
import { Props, view as view_1 } from "../Components/CSVHelper.fs.js";
import { container } from "../fable_modules/Fulma.2.16.0/Layouts/Container.fs.js";
import { Option as Option_5, button } from "../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import { DOMAttr } from "../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";

export class Types_Screen extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Graph", "Params", "Alarms", "Download", "API", "Admin", "Share"];
    }
}

export function Types_Screen$reflection() {
    return union_type("Page.View.Types.Screen", [], Types_Screen, () => [[], [], [], [], [], [], []]);
}

export class Types_DataKind extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Email", "PhoneNumber"];
    }
}

export function Types_DataKind$reflection() {
    return union_type("Page.View.Types.DataKind", [], Types_DataKind, () => [[["Item", string_type]], [["Item", string_type]]]);
}

export class Types_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Route", "Connect", "UpdatePass", "OnConnect", "OnDisplayErrorMessage", "OnDisplayError", "OnDisplaySuccess"];
    }
}

export function Types_Msg$reflection() {
    return union_type("Page.View.Types.Msg", [], Types_Msg, () => [[["Item", Route$reflection()]], [], [["Item", string_type]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [View$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", View$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", string_type]], [["Item", class_type("System.Exception")]], []]);
}

export class Types_Model extends Record {
    constructor(Data, Values, Error$, ShareCode, Pass, Connected, Blocked) {
        super();
        this.Data = Data;
        this.Values = Values;
        this.Error = Error$;
        this.ShareCode = ShareCode;
        this.Pass = Pass;
        this.Connected = Connected;
        this.Blocked = Blocked;
    }
}

export function Types_Model$reflection() {
    return record_type("Page.View.Types.Model", [], Types_Model, () => [["Data", option_type(View$reflection())], ["Values", option_type(list_type(DataList$reflection()))], ["Error", option_type(string_type)], ["ShareCode", string_type], ["Pass", string_type], ["Connected", bool_type], ["Blocked", bool_type]]);
}

export function Types_initialModel(code) {
    return new Types_Model(void 0, void 0, void 0, code, "", false, false);
}

export function Command_connect(model) {
    return [model, Cmd_OfPromise_either((tupledArg) => Public_view(tupledArg[0], tupledArg[1]), [model.ShareCode, model.Pass], (response) => ((arg0) => (new Types_Msg(3, arg0)))(response), (response_1) => ((arg0_1) => (new Types_Msg(5, arg0_1)))(response_1))];
}

export function State_update(msg, model) {
    switch (msg.tag) {
        case 1: {
            return Command_connect(model);
        }
        case 2: {
            const char = msg.fields[0];
            if (model.Blocked) {
                return [model, Cmd_none()];
            }
            else if (model.Pass.length < 5) {
                return [new Types_Model(model.Data, model.Values, model.Error, model.ShareCode, model.Pass + char, model.Connected, model.Blocked), Cmd_none()];
            }
            else {
                return [new Types_Model(model.Data, model.Values, model.Error, model.ShareCode, model.Pass + char, model.Connected, true), Cmd_OfFunc_result(new Types_Msg(1))];
            }
        }
        case 3: {
            const sensor = msg.fields[0];
            if (sensor.tag === 1) {
                return [new Types_Model(model.Data, model.Values, model.Error, model.ShareCode, "", model.Connected, false), Cmd_OfFunc_result(new Types_Msg(4, "Code erroné. Veuillez réessayer. (6 chiffres requis)"))];
            }
            else {
                return [new Types_Model(sensor.fields[0], model.Values, model.Error, model.ShareCode, "", true, false), Cmd_OfFunc_result(new Types_Msg(6))];
            }
        }
        default: {
            return [model, Cmd_none()];
        }
    }
}

export class View_SimpleChartData extends Record {
    constructor(date, value, k) {
        super();
        this.date = date;
        this.value = value;
        this.k = k;
    }
}

export function View_SimpleChartData$reflection() {
    return record_type("Page.View.View.SimpleChartData", [], View_SimpleChartData, () => [["date", string_type], ["value", float64_type], ["k", float64_type]]);
}

export function View_root(model, dispatch) {
    let children_2, children, props_1, matchValue_3;
    if (model.Connected) {
        const matchValue_1 = model.Data;
        if (matchValue_1 != null) {
            const data = matchValue_1;
            let graphs;
            const matchValue_2 = data.Sensor.SensorKind;
            switch (matchValue_2.tag) {
                case 6:
                case 38:
                case 37:
                case 2:
                case 43:
                case 1: {
                    graphs = singleton((children_2 = [columns(empty(), toList(map((seq) => {
                        const last = last_2(seq.List);
                        let v_3;
                        const _arg1_1 = last.Value;
                        switch (_arg1_1.tag) {
                            case 2: {
                                v_3 = _arg1_1.fields[0];
                                break;
                            }
                            case 1: {
                                throw (new Error("not handled"));
                                break;
                            }
                            default: {
                                v_3 = _arg1_1.fields[0];
                            }
                        }
                        const d = localFormat(french, "dd/MM HH:mm", toLocalTime(last.Date));
                        const last_1 = toText(printf("%.2f"))(v_3);
                        return column(empty(), singleton(notification(empty(), ofArray([h5(empty())(singleton(seq.Name)), h1(empty())(singleton(last_1))]))));
                    }, data.Probes.Docs))), section(singleton(new Option(4, singleton(new Modifier_IModifier(26, new Screen(3), true)))), singleton((children = singleton(graph(data.Probes.Docs, data.Sensor.SensorKind)), (props_1 = {
                        width: "100%",
                        height: "80%",
                        minHeight: 300,
                        minWidth: 250,
                    }, react.createElement(ResponsiveContainer, props_1, ...children)))))], react.createElement("div", {}, ...children_2)));
                    break;
                }
                default: {
                    graphs = map_1((seq_1) => {
                        let children_16, children_14, props_5, props_7, props_9, props_11, props_13, props_15, props_17;
                        const data_1 = toArray(map_1((value_1) => {
                            let v_7;
                            const _arg2 = value_1.Value;
                            switch (_arg2.tag) {
                                case 2: {
                                    v_7 = _arg2.fields[0];
                                    break;
                                }
                                case 1: {
                                    throw (new Error("not handled"));
                                    break;
                                }
                                default: {
                                    v_7 = _arg2.fields[0];
                                }
                            }
                            const date_4 = localFormat(french, "dd/MM HH:mm", toLocalTime(value_1.Date));
                            return new View_SimpleChartData(toText(printf("%s"))(date_4), v_7, v_7 + 1);
                        }, seq_1.List));
                        const children_18 = [h2(empty())(singleton(seq_1.Name)), (children_16 = singleton((children_14 = ofArray([(props_5 = {
                            type: "linear",
                            dataKey: "value",
                            stroke: "black",
                            strokeWidth: 1,
                        }, react.createElement(Line, props_5)), (props_7 = {
                            stroke: "rgba(0,0,0,0.3)",
                            strokeWidth: 1,
                        }, react.createElement(CartesianGrid, props_7)), (props_9 = {
                            dataKey: "date",
                            strokeWidth: 0,
                            stroke: "black",
                        }, react.createElement(XAxis, props_9)), (props_11 = {
                            tickLine: true,
                            strokeWidth: 0.1,
                            stroke: "black",
                        }, react.createElement(YAxis, props_11)), (props_13 = {}, react.createElement(Tooltip, props_13))]), (props_15 = {
                            width: 300,
                            height: 300,
                            data: data_1,
                        }, react.createElement(LineChart, props_15, ...children_14)))), (props_17 = {
                            width: "100%",
                            height: "80%",
                            minHeight: 300,
                            minWidth: 250,
                        }, react.createElement(ResponsiveContainer, props_17, ...children_16)))];
                        return react.createElement("div", {}, ...children_18);
                    }, data.Probes.Docs);
                }
            }
            const csv = toList(map((blist) => columns(empty(), toList(blist)), view_1(new Props(data.Sensor.Name, (matchValue_3 = data.Sensor.Title, (matchValue_3 == null) ? "" : matchValue_3), data.Sensor.Birthday, (tupledArg) => {
                dispatch(new Types_Msg(0, new Route(14, tupledArg[0], tupledArg[1], tupledArg[2], tupledArg[3])));
            }, void 0, new DownloadTimePeriod(0)))));
            let name_2;
            const matchValue_4 = data.Sensor.Title;
            name_2 = ((matchValue_4 == null) ? data.Sensor.Name : matchValue_4);
            return container(empty(), ofArray([notification(singleton(new Option_1(0, new Color_IColor(1))), singleton(h1(singleton(new Option_2(10, singleton(new Modifier_IModifier(1, new Color_IColor(2))))))(singleton(name_2)))), react.createElement("div", {}, ...graphs), section(empty(), append(singleton(h5(empty())(singleton(react.createElement("i", {}, "Appuyez sur un mois pour télécharger les données au format CSV.")))), csv))]));
        }
        else {
            return h5(singleton(new Option_2(10, singleton(new Modifier_IModifier(1, new Color_IColor(8))))))(singleton("Données inaccessibles. Merci de recharger la page"));
        }
    }
    else {
        const row = (numbers) => columns(singleton(new Option_3(4)), map_1((n) => column(singleton(new Option_4(0, new Screen(0), new ISize(1))), singleton(button(ofArray([new Option_5(4), new Option_5(0, new Color_IColor(1)), new Option_5(16, model.Blocked), new Option_5(20, ofArray([new Modifier_IModifier(3, new Screen(1), new TextSize_Option(1)), new Modifier_IModifier(3, new Screen(3), new TextSize_Option(3))])), new Option_5(17, singleton(new DOMAttr(40, (_arg1) => {
            dispatch(new Types_Msg(2, n));
        })))]), singleton(n)))), numbers));
        return container(empty(), singleton(columns(singleton(new Option_3(4)), ofArray([column(empty(), empty()), column(ofArray([new Option_4(0, new Screen(1), new ISize(20)), new Option_4(4, singleton(new Modifier_IModifier(5, new Screen(0), new TextAlignment_Option(0))))]), ofArray([h5(empty())(singleton("Veuillez taper le code d\u0027accès pour accéder aux informations du capteur.")), row(ofArray(["7", "8", "9"])), row(ofArray(["4", "5", "6"])), row(ofArray(["1", "2", "3"])), row(ofArray(["#", "0", "*"]))])), column(empty(), empty())]))));
    }
}

