import {
  LineChart,
  Line,
  ReferenceLine,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
  Area,
} from "recharts";
import * as React from "react";
import { Brush } from "recharts";

const data = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

const Chart = (props) => {
  const data = props.data.map((x) => {
    if (props.min && props.minFromValue) {
      if (props.min < props.minFromValue) x.minRange = [0, props.min];
      else x.minRange = [props.min, props.minFromValue];
    }
    if (props.max && props.maxFromValue) {
      x.maxRange = [props.max, props.maxFromValue];
    }
    return x;
  });

  const minHeight = props.minHeight ? props.minHeight : 300;
  const minWidth = props.minWidth ? props.minWidth : minHeight * 0.75;
  const brushHeight = props.brushHeight ? props.brushHeight : 20;

  return (
    <>
      <ResponsiveContainer
        width="100%"
        height="80%"
        minHeight={minHeight}
        minWidth={minWidth}
      >
        <ComposedChart width={minWidth} height={minHeight} data={data}>
          <Line
            type="linear"
            dataKey="value"
            strokeWidth={1}
            stroke="black"
            dot={false}
          />

          <CartesianGrid strokeWidth={1} stroke="rgba(0,0,0,0.2)" />

          <XAxis
            dataKey="date"
            tick={{
              strokeWidth: 0.1,
              stroke: "black",

            }}
          />

          <YAxis
            tick={{
              strokeWidth: 0.1,
              stroke: "black",
            }}
            type="number"
            domain={["auto", "auto"]}
          />

          <Tooltip />

          <Brush
            dataKey="date"
            height={brushHeight}
            stroke="#00A78E"
            fill="rgb(0,209,178)"
          />

          {/* <Area dataKey="minRange" fill="rgba(255,102,102,0.1)" />
          <Area dataKey="maxRange" fill="rgba(255,102,102,0.1)" /> */}

          {props.min ? (
            <ReferenceLine
              y={props.min}
              label={"min"}
              stroke={"red"}
              strokeWidth={1}
            />
          ) : (
            <></>
          )}
          {props.max ? (
            <ReferenceLine
              y={props.max}
              label={"max"}
              stroke={"red"}
              strokeWidth={1}
            />
          ) : (
            <></>
          )}
        </ComposedChart>
      </ResponsiveContainer>
    </>
  );
};
//           <Area dataKey="maxRange" fill="red" />

export default Chart;
