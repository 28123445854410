import { printf, toText } from "./fable_modules/fable-library.3.7.0/String.js";
import { oneOf, str, s, map } from "./fable_modules/Fable.Elmish.Browser.3.0.4/parser.fs.js";
import { Route } from "./Types.fs.js";
import { ofArray, collect } from "./fable_modules/fable-library.3.7.0/List.js";
import { HTMLAttr } from "./fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { Navigation_newUrl, Navigation_modifyUrl } from "./fable_modules/Fable.Elmish.Browser.3.0.4/navigation.fs.js";

function toHash(page) {
    switch (page.tag) {
        case 9: {
            return "#mp";
        }
        case 1: {
            return "#up";
        }
        case 0: {
            return "#fp";
        }
        case 2: {
            return "#sensors";
        }
        case 3: {
            return "#opt";
        }
        case 4: {
            return "#status";
        }
        case 5: {
            return "#monitoring";
        }
        case 6: {
            return "#tags";
        }
        case 7: {
            return "#welcome";
        }
        case 8: {
            return "#cp";
        }
        case 10: {
            return toText(printf("#login/%s"))(page.fields[0]);
        }
        case 18: {
            return toText(printf("#probe/%s/%s/%s"))(page.fields[0])(page.fields[1])(page.fields[2]);
        }
        case 11: {
            return toText(printf("#sensor/%s"))(page.fields[0]);
        }
        case 13: {
            return toText(printf("#view/%s"))(page.fields[0]);
        }
        default: {
            return "#status";
        }
    }
}

export const pageParser = (() => {
    let parseBefore, parseBefore_2, parseBefore_4, parseBefore_10, parseBefore_8, parseBefore_6, parseBefore_12, parseBefore_14;
    const parsers = ofArray([map(new Route(3), s("opt")), map(new Route(0), s("fp")), map(new Route(1), s("up")), map(new Route(2), s("sensors")), map(new Route(4), s("status")), map(new Route(5), s("monitoring")), map(new Route(6), s("tags")), map(new Route(7), s("welcome")), map((arg0) => (new Route(10, arg0)), (parseBefore = s("login"), (state_1) => collect((state) => str(state), parseBefore(state_1)))), map((arg0_1) => (new Route(8, arg0_1)), (parseBefore_2 = s("cp"), (state_4) => collect((state_3) => str(state_3), parseBefore_2(state_4)))), map((arg0_2) => (new Route(9, arg0_2)), (parseBefore_4 = s("ml"), (state_7) => collect((state_6) => str(state_6), parseBefore_4(state_7)))), map((x) => ((y) => ((z) => ((tupledArg) => (new Route(18, tupledArg[0], tupledArg[1], tupledArg[2])))([x, y, z]))), (parseBefore_10 = ((parseBefore_8 = ((parseBefore_6 = s("probe"), (state_10) => collect((state_9) => str(state_9), parseBefore_6(state_10)))), (state_13) => collect((state_12) => str(state_12), parseBefore_8(state_13)))), (state_16) => collect((state_15) => str(state_15), parseBefore_10(state_16)))), map((arg0_4) => (new Route(11, arg0_4)), (parseBefore_12 = s("sensor"), (state_19) => collect((state_18) => str(state_18), parseBefore_12(state_19)))), map((arg0_5) => (new Route(13, arg0_5)), (parseBefore_14 = s("view"), (state_22) => collect((state_21) => str(state_21), parseBefore_14(state_22))))]);
    return (state_24) => oneOf(parsers, state_24);
})();

export function href(route) {
    return new HTMLAttr(94, toHash(route));
}

export function modifyUrl(route) {
    return Navigation_modifyUrl(toHash(route));
}

export function newUrl(route) {
    return Navigation_newUrl(toHash(route));
}

export function modifyLocation(route) {
    window.location.href = toHash(route);
}

