import { Union } from "./fable_modules/fable-library.3.7.0/Types.js";
import { union_type, int32_type, string_type } from "./fable_modules/fable-library.3.7.0/Reflection.js";

export class Route extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ForgetPassword", "UpdatePassword", "Sensors", "AppOptions", "Status", "Monitoring", "SensorTags", "Welcome", "ChangePassword", "UseMagicLink", "Login", "Sensor", "Battery", "View", "CSV", "PDF", "WeekCSV", "WeekPDF", "Probe"];
    }
}

export function Route$reflection() {
    return union_type("Front.Types.Route", [], Route, () => [[], [], [], [], [], [], [], [], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item1", string_type], ["Item2", string_type], ["Item3", int32_type], ["Item4", int32_type]], [["Item1", string_type], ["Item2", string_type], ["Item3", int32_type], ["Item4", int32_type]], [["Item1", string_type], ["Item2", string_type], ["Item3", string_type], ["Item4", string_type], ["Item5", int32_type]], [["Item1", string_type], ["Item2", string_type], ["Item3", string_type], ["Item4", string_type], ["Item5", int32_type]], [["Item1", string_type], ["Item2", string_type], ["Item3", string_type]]]);
}

export class LoginKind extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Standard", "MG", "Occion"];
    }
}

export function LoginKind$reflection() {
    return union_type("Front.Types.LoginKind", [], LoginKind, () => [[], [], []]);
}

export class DownloadTimePeriod extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Month", "Week"];
    }
}

export function DownloadTimePeriod$reflection() {
    return union_type("Front.Types.DownloadTimePeriod", [], DownloadTimePeriod, () => [[], []]);
}

