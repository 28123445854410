const JSZip = require("jszip");

const zipTo = (filename) => {
  const zip = new JSZip();
  zip.file("Hello.txt", "Hello World\n");
  zip.generateAsync({ type: "blob" }).then(function (content) {
    saveAs(content, `${filename}.zip`);
  });
};

const zipFiles = (filename, files) => {
  const zip = new JSZip();
  files.forEach((element) => {
    zip.file(element.name, element.blob);
  });
  zip.generateAsync({ type: "blob" }).then(function (content) {
    saveAs(content, `${filename}.zip`);
  });
};

export { zipTo, zipFiles };
