import { Union } from "../../fable-library.3.7.0/Types.js";
import { Common_parseOptions, Common_GenericOptions__AddModifiers_5BB435D5, Common_GenericOptions__AddProps_416C4D0B, Reflection_getCaseName, Common_GenericOptions__AddClass_Z721C83C5, Common_GenericOptions_Parse_9AE2F7C, Common_GenericOptions__ToReactElement_Z6D3CD4B7, Modifier_IModifier$reflection, Size_ISize$reflection } from "../Common.fs.js";
import { union_type, string_type, list_type, class_type } from "../../fable-library.3.7.0/Reflection.js";
import * as react from "react";
import { keyValueList } from "../../fable-library.3.7.0/MapUtil.js";
import { some } from "../../fable-library.3.7.0/Option.js";

export class Option extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Size", "Props", "CustomClass", "Modifiers"];
    }
}

export function Option$reflection() {
    return union_type("Fulma.Media.Option", [], Option, () => [[["Item", Size_ISize$reflection()]], [["Item", list_type(class_type("Fable.React.Props.IHTMLProp"))]], [["Item", string_type]], [["Item", list_type(Modifier_IModifier$reflection())]]]);
}

export function media(options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, (result, option) => {
        let pattern_matching_result, size, props, customClass, modifiers;
        if (option.tag === 1) {
            pattern_matching_result = 2;
            props = option.fields[0];
        }
        else if (option.tag === 2) {
            pattern_matching_result = 3;
            customClass = option.fields[0];
        }
        else if (option.tag === 3) {
            pattern_matching_result = 4;
            modifiers = option.fields[0];
        }
        else if (option.fields[0].tag === 0) {
            pattern_matching_result = 0;
        }
        else if (option.fields[0].tag === 1) {
            pattern_matching_result = 0;
        }
        else {
            pattern_matching_result = 1;
            size = option.fields[0];
        }
        switch (pattern_matching_result) {
            case 0: {
                console.warn(some("`is-small` and `is-medium` are not valid sizes for the media component"));
                return result;
            }
            case 1: {
                return Common_GenericOptions__AddClass_Z721C83C5(result, Reflection_getCaseName(size));
            }
            case 2: {
                return Common_GenericOptions__AddProps_416C4D0B(result, props);
            }
            case 3: {
                return Common_GenericOptions__AddClass_Z721C83C5(result, customClass);
            }
            case 4: {
                return Common_GenericOptions__AddModifiers_5BB435D5(result, modifiers);
            }
        }
    }, "media"), (props_1, children_1) => react.createElement("article", keyValueList(props_1, 1), ...children_1), children);
}

export function left(options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, (result, option) => Common_parseOptions(result, option), "media-left"), (props, children_1) => react.createElement("div", keyValueList(props, 1), ...children_1), children);
}

export function right(options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, (result, option) => Common_parseOptions(result, option), "media-right"), (props, children_1) => react.createElement("div", keyValueList(props, 1), ...children_1), children);
}

export function content(options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, (result, option) => Common_parseOptions(result, option), "media-content"), (props, children_1) => react.createElement("div", keyValueList(props, 1), ...children_1), children);
}

