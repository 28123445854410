import { Union } from "../../fable-library.3.7.0/Types.js";
import { union_type, list_type, class_type, string_type } from "../../fable-library.3.7.0/Reflection.js";
import { Common_GenericOptions__AddModifiers_5BB435D5, Common_GenericOptions__AddProps_416C4D0B, Common_GenericOptions__RemoveClass_Z721C83C5, Common_GenericOptions__AddClass_Z721C83C5, Common_GenericOptions__AddCaseName_1505, Common_GenericOptions_Parse_9AE2F7C, Common_GenericOptions__ToReactElement_Z6D3CD4B7, Modifier_IModifier$reflection } from "../Common.fs.js";
import * as react from "react";
import { keyValueList } from "../../fable-library.3.7.0/MapUtil.js";
import { cons } from "../../fable-library.3.7.0/List.js";

export class Option extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["is-1", "is-2", "is-3", "is-4", "is-5", "is-6", "subtitle", "is-spaced", "CustomClass", "Props", "Modifiers"];
    }
}

export function Option$reflection() {
    return union_type("Fulma.Heading.Option", [], Option, () => [[], [], [], [], [], [], [], [], [["Item", string_type]], [["Item", list_type(class_type("Fable.React.Props.IHTMLProp"))]], [["Item", list_type(Modifier_IModifier$reflection())]]]);
}

export function title(element, options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, (result, option) => {
        switch (option.tag) {
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 7: {
                return Common_GenericOptions__AddCaseName_1505(result, option);
            }
            case 6: {
                return Common_GenericOptions__AddClass_Z721C83C5(Common_GenericOptions__RemoveClass_Z721C83C5(result, "title"), "subtitle");
            }
            case 9: {
                return Common_GenericOptions__AddProps_416C4D0B(result, option.fields[0]);
            }
            case 8: {
                return Common_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            }
            case 10: {
                return Common_GenericOptions__AddModifiers_5BB435D5(result, option.fields[0]);
            }
            default: {
                return Common_GenericOptions__AddCaseName_1505(result, option);
            }
        }
    }, "title"), element, children);
}

export function h1(options) {
    return (children_3) => title((props, children) => react.createElement("h1", keyValueList(props, 1), ...children), cons(new Option(0), options), children_3);
}

export function h2(options) {
    return (children_3) => title((props, children) => react.createElement("h2", keyValueList(props, 1), ...children), cons(new Option(1), options), children_3);
}

export function h3(options) {
    return (children_3) => title((props, children) => react.createElement("h3", keyValueList(props, 1), ...children), cons(new Option(2), options), children_3);
}

export function h4(options) {
    return (children_3) => title((props, children) => react.createElement("h4", keyValueList(props, 1), ...children), cons(new Option(3), options), children_3);
}

export function h5(options) {
    return (children_3) => title((props, children) => react.createElement("h5", keyValueList(props, 1), ...children), cons(new Option(4), options), children_3);
}

export function h6(options) {
    return (children_3) => title((props, children) => react.createElement("h6", keyValueList(props, 1), ...children), cons(new Option(5), options), children_3);
}

export function p(opts, children) {
    return title((props, children_1) => react.createElement("p", keyValueList(props, 1), ...children_1), opts, children);
}

