import { Record, Union } from "../fable_modules/fable-library.3.7.0/Types.js";
import { Route, Route$reflection } from "../Types.fs.js";
import { record_type, bool_type, union_type } from "../fable_modules/fable-library.3.7.0/Reflection.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { toText, printf, toConsole } from "../fable_modules/fable-library.3.7.0/String.js";
import { Inside_Button_Small, Inside_Navbar_Brand, Inside_ButtonSmall } from "../GogoFulma.fs.js";
import { partialApply } from "../fable_modules/fable-library.3.7.0/Util.js";
import { Fa_IconOption } from "../fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { Color_IColor } from "../fable_modules/Fulma.2.16.0/Common.fs.js";
import { Item_div, End_div, Item_a, Start_div, Menu_Option, menu as menu_1, Burger_Option, burger as burger_1, Option, navbar } from "../fable_modules/Fulma.2.16.0/Components/Navbar.fs.js";
import { empty, singleton, ofArray } from "../fable_modules/fable-library.3.7.0/List.js";
import { DOMAttr } from "../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import * as react from "react";
import { FrontVersionNumber } from "../Shared.fs.js";

export class Types_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Help", "Disconnect", "ShowChangeLog", "DoSomething", "Back", "Options", "Goto", "Status", "ToggleBurger", "ADMUsers", "ADMMonitoring", "ADMContracts"];
    }
}

export function Types_Msg$reflection() {
    return union_type("Page.Navbar.Types.Msg", [], Types_Msg, () => [[], [], [], [], [], [], [["Item", Route$reflection()]], [], [], [], [], []]);
}

export class Types_Model extends Record {
    constructor(IsBurgerOpen) {
        super();
        this.IsBurgerOpen = IsBurgerOpen;
    }
}

export function Types_Model$reflection() {
    return record_type("Page.Navbar.Types.Model", [], Types_Model, () => [["IsBurgerOpen", bool_type]]);
}

export function State_init() {
    return new Types_Model(false);
}

export function State_update(msg, model) {
    if (msg.tag === 8) {
        return [new Types_Model(!model.IsBurgerOpen), Cmd_none()];
    }
    else {
        return [model, Cmd_none()];
    }
}

export function View_root(model, user, lk, dispatch) {
    let toColumn, title2, matchValue, sensors, monitoring;
    toConsole(printf("%A"))(lk);
    const doSomething = (msg, _arg1) => {
        dispatch(msg);
        dispatch(new Types_Msg(8));
    };
    const disconnect = Inside_ButtonSmall(partialApply(1, doSomething, [new Types_Msg(1)]), new Fa_IconOption(11, "fas fa-times-circle"), new Color_IColor(1), "Deconnecter");
    const help = Inside_ButtonSmall(partialApply(1, doSomething, [new Types_Msg(0)]), new Fa_IconOption(11, "fas fa-question-circle"), new Color_IColor(1), "Aide");
    return navbar(ofArray([new Option(1), new Option(0, new Color_IColor(1))]), ofArray([Inside_Navbar_Brand(singleton(burger_1(ofArray([new Burger_Option(3, model.IsBurgerOpen ? "is-active" : ""), new Burger_Option(2, singleton(new DOMAttr(40, (_arg1_1) => {
        dispatch(new Types_Msg(8));
    })))]), ofArray([react.createElement("span", {}), react.createElement("span", {}), react.createElement("span", {})])))), menu_1(singleton(new Menu_Option(0, model.IsBurgerOpen)), singleton(Start_div(empty(), (toColumn = ((element) => Item_a(empty(), singleton(element))), (title2 = (((user == null) ? false : ((matchValue = user.UserKind, (matchValue.tag === 1) ? true : (matchValue.tag === 0)))) ? toColumn(Inside_Button_Small(new Color_IColor(8), partialApply(1, doSomething, [new Types_Msg(4)]), new Fa_IconOption(11, "fas fa-hand-peace"), "Vous êtes en mode ADMIN")) : ""), (sensors = toColumn(Inside_ButtonSmall(partialApply(1, doSomething, [new Types_Msg(4)]), new Fa_IconOption(11, "fas fa-list-ul"), new Color_IColor(1), "Mes capteurs")), (monitoring = toColumn(Inside_ButtonSmall(partialApply(1, doSomething, [new Types_Msg(6, new Route(5))]), new Fa_IconOption(11, "fas fa-chart-line"), new Color_IColor(1), "Monitoring")), ofArray([toColumn(react.createElement("img", {
        src: "logo_mg.png",
    })), sensors, monitoring, title2])))))))), End_div(empty(), ofArray([Item_div(empty(), singleton(toText(printf("IOTPOOL %s. SOLUTION D\u0027AIDE AU SUIVI VOS CAPTEURS RADIOS IOT . 🙂"))(FrontVersionNumber))), Item_div(empty(), singleton(help)), Item_div(empty(), singleton(disconnect))]))]));
}

