import { Record } from "../fable_modules/fable-library.3.7.0/Types.js";
import { record_type, lambda_type, unit_type, tuple_type, int32_type, option_type, class_type, string_type } from "../fable_modules/fable-library.3.7.0/Reflection.js";
import { DownloadTimePeriod$reflection } from "../Types.fs.js";
import { FunctionComponent_Of_Z5A158BBF } from "../fable_modules/Fable.React.7.4.3/Fable.React.FunctionComponent.fs.js";
import { box$0027 } from "../fable_modules/Fulma.2.16.0/Elements/Box.fs.js";
import { Color_IColor, Common_GenericOption, Modifier_IModifier } from "../fable_modules/Fulma.2.16.0/Common.fs.js";
import { length, concat, append, sortByDescending, reverse, skip, take, mapIndexed, map, empty, singleton, ofArray } from "../fable_modules/fable-library.3.7.0/List.js";
import { item, level } from "../fable_modules/Fulma.2.16.0/Layouts/Level.fs.js";
import { numberHash, comparePrimitives, uncurry } from "../fable_modules/fable-library.3.7.0/Util.js";
import { printf, toText } from "../fable_modules/fable-library.3.7.0/String.js";
import { month as month_2, year as year_5, now as now_1, op_Subtraction, dayOfWeek, addDays, day as day_1, create } from "../fable_modules/fable-library.3.7.0/Date.js";
import { days } from "../fable_modules/fable-library.3.7.0/TimeSpan.js";
import { toList, filter, sumBy } from "../fable_modules/fable-library.3.7.0/Seq.js";
import { rangeDouble } from "../fable_modules/fable-library.3.7.0/Range.js";
import { columns } from "../fable_modules/Fulma.2.16.0/Layouts/Columns.fs.js";
import { Option, column } from "../fable_modules/Fulma.2.16.0/Layouts/Column.fs.js";
import { DOMAttr } from "../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { UI_subButtonBar, Seq_split } from "../Utils.fs.js";
import { mediaContents } from "../Page/SensorComponents/ReactComponents.fs.js";
import { Fa_IconOption } from "../fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { List_groupBy } from "../fable_modules/fable-library.3.7.0/Seq2.js";

export class Props extends Record {
    constructor(ModuleId, ModuleName, Birthday, MonthAction, WeekAction, DownloadType, DownloadTimePeriod) {
        super();
        this.ModuleId = ModuleId;
        this.ModuleName = ModuleName;
        this.Birthday = Birthday;
        this.MonthAction = MonthAction;
        this.WeekAction = WeekAction;
        this.DownloadType = DownloadType;
        this.DownloadTimePeriod = DownloadTimePeriod;
    }
}

export function Props$reflection() {
    return record_type("Components.SensorFileDownload.Props", [], Props, () => [["ModuleId", string_type], ["ModuleName", string_type], ["Birthday", option_type(class_type("System.DateTime"))], ["MonthAction", lambda_type(tuple_type(string_type, string_type, int32_type, int32_type), unit_type)], ["WeekAction", lambda_type(tuple_type(string_type, string_type, string_type, string_type, int32_type), unit_type)], ["DownloadType", option_type(string_type)], ["DownloadTimePeriod", DownloadTimePeriod$reflection()]]);
}

export const subButtonBar = FunctionComponent_Of_Z5A158BBF((props) => box$0027(ofArray([new Common_GenericOption(2, ofArray([new Modifier_IModifier(0, props.BackgroundColor), new Modifier_IModifier(1, props.TextColor)])), new Common_GenericOption(1, singleton(["style", {
    cursor: "pointer",
}]))]), singleton(level(empty(), singleton(item(empty(), singleton(props.Name)))))), void 0, uncurry(2, void 0), void 0, "subButtonBar", "C:\\workspace\\iotpool-front3\\src\\Components\\SensorFileDownload.fs", 27);

export function mkDate(day, month, year) {
    return toText(printf("%i-%02d-%02dT00:00:00Z"))(year)(month)(day);
}

export function getMondayOfISOWeek(w, y) {
    const d = new Date(y,0,1);
    const requiredDate = ((w - 1) * 7) | 0;
    const requiredDate_1 = ((d.getDay() > 4) ? (requiredDate + 7) : requiredDate) | 0;
    d.setDate((1 - d.getDay()) + (requiredDate_1 + 1));
    return mkDate(~(~d.getDate()), ~(~(d.getMonth() + 1)), ~(~d.getFullYear()));
}

export const getWeeksForMonth = FunctionComponent_Of_Z5A158BBF((props) => {
    const getWeekFromMondaytoMonday = (month) => {
        let copyOfStruct;
        let added;
        if (month === 1) {
            const firstYearMonthDay = create(props.Year, 1, 1);
            added = ((day_1(addDays(firstYearMonthDay, (7 - (dayOfWeek(firstYearMonthDay) - 1)) % 7)) > 4) ? 1 : 0);
        }
        else {
            added = 0;
        }
        if (month === 12) {
            const firstMonthDay = create(props.Year, month, 1);
            const firstMonthMonday = addDays(firstMonthDay, (7 - (dayOfWeek(firstMonthDay) - 1)) % 7);
            const firstNextMonthDay = create(props.Year + 1, 1, 1);
            const firstNextMonthMonday = addDays(firstNextMonthDay, (7 - (dayOfWeek(firstNextMonthDay) - 1)) % 7);
            return (~(~Math.ceil(((copyOfStruct = op_Subtraction(firstNextMonthMonday, firstMonthMonday), days(copyOfStruct))) / 7))) | 0;
        }
        else {
            const firstMonthDay_1 = create(props.Year, month, 1);
            const firstMonthMonday_1 = addDays(firstMonthDay_1, (7 - (dayOfWeek(firstMonthDay_1) - 1)) % 7);
            const firstNextMonthDay_1 = create(props.Year, month + 1, 1);
            let diff;
            let copyOfStruct_1 = op_Subtraction(addDays(firstNextMonthDay_1, (7 - (dayOfWeek(firstNextMonthDay_1) - 1)) % 7), firstMonthMonday_1);
            diff = days(copyOfStruct_1);
            return ((~(~Math.ceil(diff / 7))) + added) | 0;
        }
    };
    const min = sumBy(getWeekFromMondaytoMonday, filter((x) => (x < props.MonthIndex), toList(rangeDouble(1, 1, 12))), {
        GetZero: () => 0,
        Add: (x_1, y) => (x_1 + y),
    }) | 0;
    return columns(empty(), map((x_2) => {
        const realIndex = x_2 | 0;
        const weekName = toText(printf(" %i "))(realIndex);
        const from = getMondayOfISOWeek(realIndex, props.Year);
        const ending = getMondayOfISOWeek(realIndex + 1, props.Year);
        return column(singleton(new Option(3, singleton(new DOMAttr(40, (_arg1) => {
            props.Action([props.ModuleId, props.ModuleName, from, ending, realIndex]);
        })))), singleton(subButtonBar({
            BackgroundColor: new Color_IColor(5),
            Name: weekName,
            TextColor: new Color_IColor(3),
        })));
    }, toList(rangeDouble(min + 1, 1, min + getWeekFromMondaytoMonday(props.MonthIndex)))));
}, void 0, uncurry(2, void 0), void 0, "getWeeksForMonth", "C:\\workspace\\iotpool-front3\\src\\Components\\SensorFileDownload.fs", 101);

export function view(props) {
    let monthList, matchValue, year_4, b, currentMonth, firstYear, list_12, list_4, diff;
    const toBox = FunctionComponent_Of_Z5A158BBF((bx) => columns(empty(), bx.Elements), void 0, uncurry(2, void 0), void 0, "view", "C:\\workspace\\iotpool-front3\\src\\Components\\SensorFileDownload.fs", 197);
    const now = now_1();
    return Seq_split(6, (monthList = mapIndexed((m, month) => [m + 1, month], ofArray(["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"])), (matchValue = props.Birthday, (matchValue == null) ? ((year_4 = (year_5(now) | 0), map((tupledArg_9) => toBox({
        Elements: singleton(UI_subButtonBar(toText(printf("%s %i"))(tupledArg_9[1])(year_4))),
        Month: tupledArg_9[0],
        Year: year_4,
    }), take(month_2(now), monthList)))) : ((b = matchValue, (currentMonth = (((month_2(b) > 0) ? (month_2(b) - 1) : 12) | 0), (firstYear = ((year_5(b) === year_5(now)) ? empty() : map((tupledArg) => [tupledArg[0], tupledArg[1], year_5(b)], skip(currentMonth, monthList))), map((tupledArg_7) => mediaContents({
        Color: new Color_IColor(2),
        Contents: map((tupledArg_8) => {
            let matchValue_1, dt;
            const m_8 = tupledArg_8[0] | 0;
            const label_7 = tupledArg_8[1];
            const year_3 = tupledArg_8[2] | 0;
            return toBox({
                Elements: (matchValue_1 = props.DownloadType, (matchValue_1 != null) ? ((dt = matchValue_1, ofArray([column(singleton(new Option(3, singleton(new DOMAttr(40, (_arg2) => {
                    props.MonthAction([props.ModuleId, props.ModuleName, m_8, year_3]);
                })))), singleton(subButtonBar({
                    BackgroundColor: new Color_IColor(4),
                    Name: toText(printf("%s : %s %i"))(dt)(label_7)(year_3),
                    TextColor: new Color_IColor(0),
                }))), column(empty(), singleton(getWeeksForMonth({
                    Action: props.WeekAction,
                    ModuleId: props.ModuleId,
                    ModuleName: props.ModuleName,
                    MonthIndex: m_8,
                    Year: year_3,
                })))]))) : singleton(column(singleton(new Option(3, singleton(new DOMAttr(40, (_arg1) => {
                    props.MonthAction([props.ModuleId, props.ModuleName, m_8, year_3]);
                })))), singleton(UI_subButtonBar(toText(printf("%s %i"))(label_7)(year_3)))))),
                Month: m_8,
                Year: year_3,
            });
        }, tupledArg_7[1]),
        Description: singleton("Appuyez sur un bouton pour télécharger le fichier qui vous intéresse : le mois complet ou uniquement la semaine"),
        Icon: new Fa_IconOption(11, "fas fa-calendar-week"),
        Title: toText(printf("%i"))(tupledArg_7[0]),
    }), reverse(List_groupBy((tupledArg_6) => tupledArg_6[2], reverse((list_12 = sortByDescending((tupledArg_5) => tupledArg_5[2], append((year_5(b) === year_5(now)) ? map((tupledArg_1) => [tupledArg_1[0], tupledArg_1[1], year_5(now)], (list_4 = skip(currentMonth, monthList), take(month_2(now) - currentMonth, list_4))) : map((tupledArg_2) => [tupledArg_2[0], tupledArg_2[1], year_5(now)], take(month_2(now), monthList)), append(firstYear, (diff = ((year_5(now) - year_5(b)) | 0), (diff === 0) ? empty() : ((diff === 1) ? empty() : ((diff === 2) ? map((tupledArg_3) => [tupledArg_3[0], tupledArg_3[1], year_5(b) + 1], monthList) : concat(map((i) => map((tupledArg_4) => [tupledArg_4[0], tupledArg_4[1], year_5(b) + i], monthList), toList(rangeDouble(0, 1, diff - 1))))))))), {
        Compare: (x_1, y) => comparePrimitives(x_1, y),
    }), take(~(~Math.min(24, length(list_12))), list_12))), {
        Equals: (x_2, y_1) => (x_2 === y_1),
        GetHashCode: (x_2) => numberHash(x_2),
    }))))))))));
}

