import { Union } from "./fable_modules/fable-library.3.7.0/Types.js";
import { union_type, string_type, int32_type } from "./fable_modules/fable-library.3.7.0/Reflection.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./fable_modules/Fable.Promise.3.1.3/Promise.fs.js";
import { promise } from "./fable_modules/Fable.Promise.3.1.3/PromiseImpl.fs.js";
import { keyValueList } from "./fable_modules/fable-library.3.7.0/MapUtil.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.7.0/Choice.js";
import { split, toText, printf, toConsole, join } from "./fable_modules/fable-library.3.7.0/String.js";
import { Types_HttpRequestHeaders, Types_RequestProperties } from "./fable_modules/Fable.Fetch.2.4.0/Fetch.fs.js";
import { mapIndexed, map, concat, singleton, ofArray } from "./fable_modules/fable-library.3.7.0/List.js";
import { list as list_8, string, fromString } from "./fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { Token_load } from "./SessionStorage.fs.js";
import { int32ToString, dateHash, stringHash, uncurry } from "./fable_modules/fable-library.3.7.0/Util.js";
import { Synchronize_get_Decoder, Api_Front_synchronize, Synchronize, Synchronize_Encode_586435E8, Api_Sensor_put, Api_Sensor_toggleAlarmOff, ShareUpdateResponse_get_Decoder, Api_Sensor_toggleShareView, UpdateResponse_get_Decoder, Api_Sensor_toggleShare, Api_Sensor_get, Api_rollPassword, Api_updatePassword, UpdatePassword, UpdatePassword_Encode_Z547892B4, UserLight_get_Decoder, Api_User_me, User_get_Decoder, Api_User_account, Sensor_Status_get_Decoder, GenericDocs$1_Decoder_Z1D7C0D26, Api_Sensor_status, Api_Sensor_list, Api_Front_refreshPicture, DataList_get_Decoder, Api_Front_getBattery, Api_Front_getProbes, Rev_get_Decoder, Api_User_monitoring, UserLight, UserLight_get_Empty, UserLight_Encode_Z4D912D33, Api_Admin_User_add, Api_changePassword, ChangePassword, ChangePassword_Encode_Z70BDEC65, Api_checkMagicLink, CheckMagicLink, CheckMagicLink_Encode_4A222A5, Api_sendMagicLink, Auth_ForgetPasswordResponse_get_Decoder, Api_forgetPassword, ForgetPassword, ForgetPassword_Encode_Z6C28E6B0, Auth_Response_get_Decoder, Api_signin, Signup, Signup_Encode_Z51B8234C, View_get_Decoder, Api_Front_view, Api_Front_getDataFromDateToDate, Api_Front_getData, DataListSequence_get_Decoder } from "./Shared.fs.js";
import { equals, toLocalTime, toString } from "./fable_modules/fable-library.3.7.0/Date.js";
import { fromDate } from "./fable_modules/fable-library.3.7.0/DateOffset.js";
import { fromNumber } from "./fable_modules/fable-library.3.7.0/Long.js";
import { Types_Full_EncodeForCouch_Z35750123, Types_Full_get_Decoder, Types_ProbeKind_get_toLabel } from "../../iotpool-types/Project/Iotpool.Dotnet/fable/Library.fs.js";
import { List_groupBy } from "./fable_modules/fable-library.3.7.0/Seq2.js";
import { localFormat } from "./fable_modules/Fable.Date.1.0.0/Date.Format.fs.js";
import { french as french_1 } from "./fable_modules/Fable.Date.1.0.0/Date.Local.fs.js";
import { toString as toString_1 } from "./fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { toList } from "./fable_modules/fable-library.3.7.0/Seq.js";

export class FetchError extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Status", "Json", "Exception"];
    }
}

export function FetchError$reflection() {
    return union_type("Fetch.Api.FetchError", [], FetchError, () => [[["Item1", int32_type], ["Item2", string_type]], [["Item", string_type]], [["Item", string_type]]]);
}

export function Query_fetch(url, init) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetch(url, keyValueList(init, 1)).then((_arg1) => {
        const response = _arg1;
        return (response.ok) ? (Promise.resolve(new FSharpResult$2(0, response))) : (response.text().then((_arg2) => (Promise.resolve(new FSharpResult$2(1, new FetchError(2, _arg2))))));
    }))));
}

export function Query_get(url, decoder) {
    let url_1;
    const v = join("", ["https://vapi2.iotpool.fr", url]);
    url_1 = (encodeURI(v));
    let pr_1;
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Query_fetch(url_1, ofArray([new Types_RequestProperties(0, "GET"), new Types_RequestProperties(3, "cors")])).then((_arg1) => {
        const result = _arg1;
        if (result.tag === 1) {
            return Promise.resolve(new FSharpResult$2(1, result.fields[0]));
        }
        else {
            const result_1 = result.fields[0];
            return result_1.text().then((_arg2) => {
                let matchValue;
                const text = _arg2;
                return Promise.resolve((matchValue = ((result_1.status) | 0), (matchValue === 403) ? (new FSharpResult$2(1, new FetchError(0, 403, "La ressource a été mise à jour entre temps par le système. Merci de la recharger."))) : ((matchValue === 404) ? (new FSharpResult$2(1, new FetchError(0, 404, text))) : ((matchValue === 409) ? (new FSharpResult$2(1, new FetchError(0, 409, text))) : ((matchValue === 500) ? (new FSharpResult$2(1, new FetchError(0, 500, result_1.statusText))) : (new FSharpResult$2(0, text)))))));
            });
        }
    }))));
    pr_1 = (pr.then((result_2) => {
        if (result_2.tag === 1) {
            return new FSharpResult$2(1, result_2.fields[0]);
        }
        else {
            const decoded = fromString(decoder, result_2.fields[0]);
            if (decoded.tag === 1) {
                const msg = decoded.fields[0];
                toConsole(printf("json error %A"))(msg);
                return new FSharpResult$2(1, new FetchError(1, msg));
            }
            else {
                toConsole(printf("ok !"));
                return new FSharpResult$2(0, decoded.fields[0]);
            }
        }
    }));
    return pr_1.catch((exn) => {
        let arg10_1;
        return new FSharpResult$2(1, new FetchError(2, (arg10_1 = exn.message, toText(printf("Erreur inconnue :%s. Merci de reeesayer ou de contacter l\u0027administrateur du site. (url contactée : \u0027%s\u0027)"))(arg10_1)(url_1))));
    });
}

export function Query_Public_Public(url, decoder, body) {
    let url_1;
    const v = join("", ["https://vapi2.iotpool.fr", url]);
    url_1 = (encodeURI(v));
    let pr_1;
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Query_fetch(url_1, ofArray([new Types_RequestProperties(0, "POST"), new Types_RequestProperties(1, {
        ["Content-Type"]: "application/json",
    }), new Types_RequestProperties(3, "cors"), new Types_RequestProperties(2, body)])).then((_arg1) => {
        const result = _arg1;
        if (result.tag === 1) {
            return Promise.resolve(new FSharpResult$2(1, result.fields[0]));
        }
        else {
            const result_1 = result.fields[0];
            return result_1.text().then((_arg2) => {
                let matchValue;
                const text = _arg2;
                return Promise.resolve((matchValue = ((result_1.status) | 0), (matchValue === 403) ? (new FSharpResult$2(1, new FetchError(0, 403, "La ressource a été mise à jour entre temps par le système. Merci de la recharger."))) : ((matchValue === 404) ? (new FSharpResult$2(1, new FetchError(0, 404, text))) : ((matchValue === 409) ? (new FSharpResult$2(1, new FetchError(0, 409, text))) : ((matchValue === 500) ? (new FSharpResult$2(1, new FetchError(0, 500, result_1.statusText))) : (new FSharpResult$2(0, text)))))));
            });
        }
    }))));
    pr_1 = (pr.then((result_2) => {
        if (result_2.tag === 1) {
            return new FSharpResult$2(1, result_2.fields[0]);
        }
        else {
            const decoded = fromString(decoder, result_2.fields[0]);
            if (decoded.tag === 1) {
                return new FSharpResult$2(1, new FetchError(1, decoded.fields[0]));
            }
            else {
                return new FSharpResult$2(0, decoded.fields[0]);
            }
        }
    }));
    return pr_1.catch((exn) => {
        let arg10;
        return new FSharpResult$2(1, new FetchError(2, (arg10 = exn.message, toText(printf("Erreur inconnue :%s. Merci de reeesayer ou de contacter l\u0027administrateur du site. (url contactée : \u0027%s\u0027)"))(arg10)(url_1))));
    });
}

export function Query_Public_getRaw(url, decoder) {
    let url_1;
    const v = join("", ["https://vapi2.iotpool.fr", url]);
    url_1 = (encodeURI(v));
    const token = Token_load();
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Query_fetch(url_1, ofArray([new Types_RequestProperties(0, "GET"), new Types_RequestProperties(3, "cors")])).then((_arg1) => {
        const result = _arg1;
        return (result.tag === 1) ? (Promise.resolve(new FSharpResult$2(1, result.fields[0]))) : (result.fields[0].text().then((_arg2) => (Promise.resolve(new FSharpResult$2(0, decoder(_arg2))))));
    }))));
    return pr.catch((exn) => {
        let arg10;
        return new FSharpResult$2(1, new FetchError(2, (arg10 = exn.message, toText(printf("Erreur inconnue :%s. Merci de reeesayer ou de contacter l\u0027administrateur du site. (url contactée : \u0027%s\u0027)"))(arg10)(url_1))));
    });
}

export function Query_Secure_getRaw(url, decoder) {
    let headers;
    let url_1;
    const v = join("", ["https://vapi2.iotpool.fr", url]);
    url_1 = (encodeURI(v));
    const token = Token_load();
    const withProps = ofArray([new Types_RequestProperties(0, "GET"), (headers = singleton(new Types_HttpRequestHeaders(5, toText(printf("Bearer %s"))(token))), new Types_RequestProperties(1, keyValueList(headers, 0))), new Types_RequestProperties(3, "cors")]);
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Query_fetch(url_1, withProps).then((_arg1) => {
        const result = _arg1;
        return (result.tag === 1) ? (Promise.resolve(new FSharpResult$2(1, result.fields[0]))) : (result.fields[0].text().then((_arg2) => (Promise.resolve(new FSharpResult$2(0, decoder(_arg2))))));
    }))));
    return pr.catch((exn) => {
        let arg10_1;
        return new FSharpResult$2(1, new FetchError(2, (arg10_1 = exn.message, toText(printf("Erreur inconnue :%s. Merci de reeesayer ou de contacter l\u0027administrateur du site. (url contactée : \u0027%s\u0027)"))(arg10_1)(url_1))));
    });
}

export function Query_Secure_getNoCheck(url) {
    let headers;
    let url_1;
    const v = join("", ["https://vapi2.iotpool.fr", url]);
    url_1 = (encodeURI(v));
    const token = Token_load();
    const withProps = ofArray([new Types_RequestProperties(0, "GET"), (headers = singleton(new Types_HttpRequestHeaders(5, toText(printf("Bearer %s"))(token))), new Types_RequestProperties(1, keyValueList(headers, 0))), new Types_RequestProperties(3, "cors")]);
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Query_fetch(url_1, withProps).then((_arg1) => {
        const result = _arg1;
        if (result.tag === 1) {
            return Promise.resolve(new FSharpResult$2(1, result.fields[0]));
        }
        else {
            const result_1 = result.fields[0];
            return result_1.text().then((_arg2) => {
                let matchValue;
                const text = _arg2;
                return Promise.resolve((matchValue = ((result_1.status) | 0), (matchValue === 404) ? (new FSharpResult$2(1, new FetchError(0, 404, text))) : ((matchValue === 409) ? (new FSharpResult$2(1, new FetchError(0, 409, text))) : ((matchValue === 500) ? (new FSharpResult$2(1, new FetchError(0, 500, result_1.statusText))) : (new FSharpResult$2(0, text))))));
            });
        }
    }))));
    return pr.catch((exn) => {
        let arg10_1;
        return new FSharpResult$2(1, new FetchError(2, (arg10_1 = exn.message, toText(printf("Erreur inconnue :%s. Merci de reeesayer ou de contacter l\u0027administrateur du site. (url contactée : \u0027%s\u0027)"))(arg10_1)(url_1))));
    });
}

export function Query_Secure_get(url, decoder) {
    let headers;
    let url_1;
    const v = join("", ["https://vapi2.iotpool.fr", url]);
    url_1 = (encodeURI(v));
    toConsole(printf("url : %s"))(url_1);
    const token = Token_load();
    const withProps = ofArray([new Types_RequestProperties(0, "GET"), (headers = singleton(new Types_HttpRequestHeaders(5, toText(printf("Bearer %s"))(token))), new Types_RequestProperties(1, keyValueList(headers, 0))), new Types_RequestProperties(3, "cors")]);
    let pr_1;
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (Query_fetch(url_1, withProps).then((_arg1) => {
        const result = _arg1;
        if (result.tag === 1) {
            return Promise.resolve(new FSharpResult$2(1, result.fields[0]));
        }
        else {
            const result_1 = result.fields[0];
            return result_1.text().then((_arg2) => {
                let matchValue;
                const text = _arg2;
                return Promise.resolve((matchValue = ((result_1.status) | 0), (matchValue === 404) ? (new FSharpResult$2(1, new FetchError(0, 404, text))) : ((matchValue === 409) ? (new FSharpResult$2(1, new FetchError(0, 409, text))) : ((matchValue === 500) ? (new FSharpResult$2(1, new FetchError(0, 500, result_1.statusText))) : (new FSharpResult$2(0, text))))));
            });
        }
    }))));
    pr_1 = (pr.then((result_2) => {
        if (result_2.tag === 1) {
            return new FSharpResult$2(1, result_2.fields[0]);
        }
        else {
            const data = result_2.fields[0];
            toConsole(printf("%s result"))(data);
            const decoded = fromString(decoder, data);
            if (decoded.tag === 1) {
                return new FSharpResult$2(1, new FetchError(1, decoded.fields[0]));
            }
            else {
                return new FSharpResult$2(0, decoded.fields[0]);
            }
        }
    }));
    return pr_1.catch((exn) => {
        let arg10_3;
        return new FSharpResult$2(1, new FetchError(2, (arg10_3 = exn.message, toText(printf("Erreur inconnue :%s. Merci de reeesayer ou de contacter l\u0027administrateur du site. (url contactée : \u0027%s\u0027)"))(arg10_3)(url_1))));
    });
}

export function Query_Secure_post(url, decoder, body) {
    let url_1;
    const v = join("", ["https://vapi2.iotpool.fr", url]);
    url_1 = (encodeURI(v));
    const token = Token_load();
    let pr_1;
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let headers;
        return Query_fetch(url_1, ofArray([new Types_RequestProperties(0, "POST"), (headers = ofArray([new Types_HttpRequestHeaders(11, "application/json"), new Types_HttpRequestHeaders(5, toText(printf("Bearer %s"))(token))]), new Types_RequestProperties(1, keyValueList(headers, 0))), new Types_RequestProperties(3, "cors"), new Types_RequestProperties(2, body)])).then((_arg1) => {
            const result = _arg1;
            if (result.tag === 1) {
                return Promise.resolve(new FSharpResult$2(1, result.fields[0]));
            }
            else {
                const result_1 = result.fields[0];
                return result_1.text().then((_arg2) => {
                    let matchValue;
                    const text = _arg2;
                    return Promise.resolve((matchValue = ((result_1.status) | 0), (matchValue === 404) ? (new FSharpResult$2(1, new FetchError(0, 404, text))) : ((matchValue === 409) ? (new FSharpResult$2(1, new FetchError(0, 409, text))) : ((matchValue === 500) ? (new FSharpResult$2(1, new FetchError(0, 500, result_1.statusText))) : (new FSharpResult$2(0, text))))));
                });
            }
        });
    }));
    pr_1 = (pr.then((result_2) => {
        if (result_2.tag === 1) {
            return new FSharpResult$2(1, result_2.fields[0]);
        }
        else {
            const decoded = fromString(decoder, result_2.fields[0]);
            if (decoded.tag === 1) {
                return new FSharpResult$2(1, new FetchError(1, decoded.fields[0]));
            }
            else {
                return new FSharpResult$2(0, decoded.fields[0]);
            }
        }
    }));
    return pr_1.catch((exn) => {
        let arg10_1;
        return new FSharpResult$2(1, new FetchError(2, (arg10_1 = exn.message, toText(printf("Erreur inconnue :%s. Merci de reeesayer ou de contacter l\u0027administrateur du site. (url contactée : \u0027%s\u0027)"))(arg10_1)(url_1))));
    });
}

export function Query_Secure_postRaw(url, body) {
    let url_1;
    const v = join("", ["https://vapi2.iotpool.fr", url]);
    url_1 = (encodeURI(v));
    const token = Token_load();
    let pr_1;
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let headers;
        return Query_fetch(url_1, ofArray([new Types_RequestProperties(0, "POST"), (headers = ofArray([new Types_HttpRequestHeaders(11, "application/json"), new Types_HttpRequestHeaders(5, toText(printf("Bearer %s"))(token))]), new Types_RequestProperties(1, keyValueList(headers, 0))), new Types_RequestProperties(3, "cors"), new Types_RequestProperties(2, body)])).then((_arg1) => {
            const result = _arg1;
            if (result.tag === 1) {
                return Promise.resolve(new FSharpResult$2(1, result.fields[0]));
            }
            else {
                const result_1 = result.fields[0];
                return result_1.text().then((_arg2) => {
                    let matchValue;
                    const text = _arg2;
                    return Promise.resolve((matchValue = ((result_1.status) | 0), (matchValue === 404) ? (new FSharpResult$2(1, new FetchError(0, 404, text))) : ((matchValue === 409) ? (new FSharpResult$2(1, new FetchError(0, 409, text))) : ((matchValue === 500) ? (new FSharpResult$2(1, new FetchError(0, 500, result_1.statusText))) : (new FSharpResult$2(0, text))))));
                });
            }
        });
    }));
    pr_1 = (pr.then((result_2) => result_2));
    return pr_1.catch((exn) => {
        let arg10_1;
        return new FSharpResult$2(1, new FetchError(2, (arg10_1 = exn.message, toText(printf("Erreur inconnue :%s. Merci de reeesayer ou de contacter l\u0027administrateur du site. (url contactée : \u0027%s\u0027)"))(arg10_1)(url_1))));
    });
}

export function Query_Secure_put(url, decoder, body) {
    let url_1;
    const v = join("", ["https://vapi2.iotpool.fr", url]);
    url_1 = (encodeURI(v));
    const token = Token_load();
    let pr_1;
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let headers;
        return Query_fetch(url_1, ofArray([new Types_RequestProperties(0, "PUT"), (headers = ofArray([new Types_HttpRequestHeaders(11, "application/json"), new Types_HttpRequestHeaders(5, toText(printf("Bearer %s"))(token))]), new Types_RequestProperties(1, keyValueList(headers, 0))), new Types_RequestProperties(3, "cors"), new Types_RequestProperties(2, body)])).then((_arg1) => {
            const result = _arg1;
            if (result.tag === 1) {
                return Promise.resolve(new FSharpResult$2(1, result.fields[0]));
            }
            else {
                const result_1 = result.fields[0];
                return result_1.text().then((_arg2) => {
                    let matchValue;
                    return Promise.resolve((matchValue = ((result_1.status) | 0), (matchValue === 404) ? (new FSharpResult$2(1, new FetchError(0, 404, "Cette ressource est inconnue."))) : ((matchValue === 409) ? (new FSharpResult$2(1, new FetchError(0, 409, "Un autre utilisateur a modifié ces informations entre temps. Merci de recharger la page."))) : ((matchValue === 500) ? (new FSharpResult$2(1, new FetchError(0, 500, result_1.statusText))) : (new FSharpResult$2(0, _arg2))))));
                });
            }
        });
    }));
    pr_1 = (pr.then((result_2) => {
        if (result_2.tag === 1) {
            return new FSharpResult$2(1, result_2.fields[0]);
        }
        else {
            const decoded = fromString(decoder, result_2.fields[0]);
            if (decoded.tag === 1) {
                toConsole(printf("put error"));
                return new FSharpResult$2(1, new FetchError(1, decoded.fields[0]));
            }
            else {
                toConsole(printf("put ok"));
                return new FSharpResult$2(0, decoded.fields[0]);
            }
        }
    }));
    return pr_1.catch((exn) => {
        let arg10_1;
        return new FSharpResult$2(1, new FetchError(2, (arg10_1 = exn.message, toText(printf("Erreur :%s. (adresse contactée : \u0027%s\u0027)"))(arg10_1)(url_1))));
    });
}

export class OutputKind extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["CSV", "PDF"];
    }
}

export function OutputKind$reflection() {
    return union_type("Fetch.Api.OutputKind", [], OutputKind, () => [[], []]);
}

function prepareProbeValuesAsString(sensorId, outputKind, rawData) {
    const decoded = fromString(uncurry(2, DataListSequence_get_Decoder()), rawData);
    if (decoded.tag === 0) {
        const result = join("\r\n", concat(map((data_1) => map((probeData) => {
            const date = probeData.Date;
            let value;
            const matchValue = probeData.Value;
            switch (matchValue.tag) {
                case 2: {
                    value = matchValue.fields[0];
                    break;
                }
                case 1: {
                    throw (new Error("no string value for reports"));
                    break;
                }
                default: {
                    value = matchValue.fields[0];
                }
            }
            const realDate = toString(date, "yyyy-MM-ddTHH:mm:ssZ");
            let timestamp;
            let copyOfStruct = fromDate(date);
            timestamp = fromNumber(copyOfStruct.getTime() / 1000);
            return toText(printf("\"%i\";\"%s\";\"%s\";\"%s\";\"%.2f\""))(timestamp)(realDate)(sensorId)(data_1.Name)(value);
        }, data_1.List), decoded.fields[0].Docs)));
        if (outputKind.tag === 0) {
            return "data:text/csv;charset=utf-8," + ("﻿timestamp;date(ISO8601);numero de serie;sonde;valeur\r\n" + result);
        }
        else {
            return "\"timestamp\";\"date(ISO8601)\";\"numero de serie\";\"sonde\";\"valeur\"\r\n" + result;
        }
    }
    else {
        throw (new Error(decoded.fields[0]));
    }
}

function prepareProbeValuesAsStringNext(sensor, outputKind, rawData) {
    const decoded = fromString(uncurry(2, DataListSequence_get_Decoder()), rawData);
    if (decoded.tag === 0) {
        const data = decoded.fields[0];
        let result;
        const getProbeTitle = (probeTitle, probeData, probeName) => {
            if (probeTitle == null) {
                if (probeData != null) {
                    const probe_1 = probeData;
                    return Types_ProbeKind_get_toLabel()(probe_1);
                }
                else {
                    return probeName;
                }
            }
            else {
                return probeTitle;
            }
        };
        const headers = join(";", map((probeId) => toText(printf("\"%s\""))(probeId), mapIndexed((index, tupledArg) => {
            const probeName_1 = tupledArg[0];
            switch (index) {
                case 0: {
                    return "Batterie";
                }
                case 1: {
                    return getProbeTitle(sensor.S1Title, sensor.S1, probeName_1);
                }
                case 2: {
                    return getProbeTitle(sensor.S2Title, sensor.S2, probeName_1);
                }
                case 3: {
                    return getProbeTitle(sensor.S3Title, sensor.S3, probeName_1);
                }
                case 4: {
                    return getProbeTitle(sensor.S4Title, sensor.S4, probeName_1);
                }
                case 5: {
                    return getProbeTitle(sensor.S5Title, sensor.S5, probeName_1);
                }
                case 6: {
                    return getProbeTitle(sensor.S6Title, sensor.S6, probeName_1);
                }
                case 7: {
                    return getProbeTitle(sensor.S7Title, sensor.S7, probeName_1);
                }
                case 8: {
                    return getProbeTitle(sensor.S8Title, sensor.S8, probeName_1);
                }
                case 9: {
                    return getProbeTitle(sensor.S9Title, sensor.S9, probeName_1);
                }
                default: {
                    return probeName_1;
                }
            }
        }, List_groupBy((probe) => probe.Name, data.Docs, {
            Equals: (x, y) => (x === y),
            GetHashCode: (x) => stringHash(x),
        }))));
        result = ((toText(printf("\"timestamp\";\"date(ISO8601)\";\"date(FR)\";\"numero de serie\";%s"))(headers) + "\r\n") + join("\r\n", map((tupledArg_1) => {
            const date = tupledArg_1[0];
            const realDate = toString(date, "yyyy-MM-ddTHH:mm:ssZ");
            let timestamp;
            let copyOfStruct = fromDate(date);
            timestamp = fromNumber(copyOfStruct.getTime() / 1000);
            const french = localFormat(french_1, "dd/MM/yyyy HH:mm", toLocalTime(date));
            const values = join(";", map((probeData_2) => {
                let value;
                const matchValue = probeData_2.Value;
                switch (matchValue.tag) {
                    case 2: {
                        value = matchValue.fields[0];
                        break;
                    }
                    case 1: {
                        throw (new Error("no string value for reports"));
                        break;
                    }
                    default: {
                        value = matchValue.fields[0];
                    }
                }
                return toText(printf("\"%.2f\""))(value);
            }, tupledArg_1[1]));
            return toText(printf("\"%i\";\"%s\";\"%s\";\"%s\";%s"))(timestamp)(realDate)(french)(sensor.Name)(values);
        }, List_groupBy((probeData_1) => probeData_1.Date, concat(map((probe_2) => probe_2.List, data.Docs)), {
            Equals: (x_1, y_1) => equals(x_1, y_1),
            GetHashCode: (x_1) => dateHash(x_1),
        }))));
        if (outputKind.tag === 0) {
            return "data:text/csv;charset=utf-8," + ("﻿" + result);
        }
        else {
            return result;
        }
    }
    else {
        throw (new Error(decoded.fields[0]));
    }
}

export function Public_monthlyReport(sensorId, month, year) {
    return Query_Public_getRaw(Api_Front_getData([sensorId, int32ToString(month), int32ToString(year)]), (result) => prepareProbeValuesAsString(sensorId, new OutputKind(0), result));
}

export function Public_monthlyReportPDF(sensorId, month, year) {
    return Query_Public_getRaw(Api_Front_getData([sensorId, int32ToString(month), int32ToString(year)]), (result) => prepareProbeValuesAsString(sensorId, new OutputKind(1), result));
}

export function Public_timeReport(sensorId, from, ending) {
    return Query_Public_getRaw(Api_Front_getDataFromDateToDate([sensorId, from, ending]), (result) => prepareProbeValuesAsString(sensorId, new OutputKind(0), result));
}

export function Public_timeReportPDF(sensorId, from, ending) {
    return Query_Public_getRaw(Api_Front_getDataFromDateToDate([sensorId, from, ending]), (result) => prepareProbeValuesAsString(sensorId, new OutputKind(1), result));
}

export function Public_view(code, pass) {
    return Query_get(Api_Front_view([code, pass]), uncurry(2, View_get_Decoder()));
}

export function Auth_signIn(login, password) {
    const body = Signup_Encode_Z51B8234C(new Signup(login, password));
    return Query_Public_Public(Api_signin, uncurry(2, Auth_Response_get_Decoder()), body);
}

export function Auth_forgetPassword(login) {
    const body = ForgetPassword_Encode_Z6C28E6B0(new ForgetPassword(login));
    return Query_Public_Public(Api_forgetPassword, uncurry(2, Auth_ForgetPasswordResponse_get_Decoder()), body);
}

export function Auth_sendMagicLink(login) {
    const body = ForgetPassword_Encode_Z6C28E6B0(new ForgetPassword(login));
    return Query_Public_Public(Api_sendMagicLink, uncurry(2, Auth_ForgetPasswordResponse_get_Decoder()), body);
}

export function Auth_checkMagicLink(link) {
    const body = CheckMagicLink_Encode_4A222A5(new CheckMagicLink(link));
    return Query_Public_Public(Api_checkMagicLink, uncurry(2, Auth_Response_get_Decoder()), body);
}

export function Auth_changePassword(code, w1, w2, w3) {
    const body = ChangePassword_Encode_Z70BDEC65(new ChangePassword(w1, w2, w3, code));
    return Query_Public_Public(Api_changePassword, uncurry(2, Auth_ForgetPasswordResponse_get_Decoder()), body);
}

export function Secure_Admin_saveUser(data_0, data_1, data_2) {
    let inputRecord;
    const data = [data_0, data_1, data_2];
    return Query_Secure_post(Api_Admin_User_add, (path, value_1) => string(path, value_1), toString_1(0, UserLight_Encode_Z4D912D33((inputRecord = UserLight_get_Empty(), new UserLight(data[0], data[1], inputRecord.Operator, inputRecord.Address1, inputRecord.Address2, inputRecord.City, inputRecord.Zip, inputRecord.Tel, inputRecord.Country, inputRecord.Company, inputRecord.Web, inputRecord.Color, inputRecord.Logo, split(data[2], ["_"], null, 0)[1], inputRecord.UserKind, inputRecord.AppOptions, inputRecord.CustomPictures, inputRecord.Monitoring, inputRecord.ShareKey, inputRecord.Tags)))));
}

export function Secure_Admin_saveMonitoring(data_0, data_1, data_2) {
    let inputRecord;
    const data = [data_0, data_1, data_2];
    const body = toString_1(0, UserLight_Encode_Z4D912D33((inputRecord = UserLight_get_Empty(), new UserLight(data[0], data[1], inputRecord.Operator, inputRecord.Address1, inputRecord.Address2, inputRecord.City, inputRecord.Zip, inputRecord.Tel, inputRecord.Country, inputRecord.Company, inputRecord.Web, inputRecord.Color, inputRecord.Logo, inputRecord.Owner, inputRecord.UserKind, inputRecord.AppOptions, inputRecord.CustomPictures, data[2], inputRecord.ShareKey, inputRecord.Tags))));
    return Query_Secure_post(Api_User_monitoring, uncurry(2, Rev_get_Decoder()), body);
}

export function Secure_dataFromDateToDate(sensorId, from, ending) {
    return Query_Secure_get(Api_Front_getDataFromDateToDate([sensorId, from, ending]), uncurry(2, DataListSequence_get_Decoder()));
}

export function Secure_dataFromDateToDateCSV(sensor, from, ending, reportKind) {
    return Query_Public_getRaw(Api_Front_getDataFromDateToDate([sensor.Name, from, ending]), (result) => prepareProbeValuesAsStringNext(sensor, reportKind, result));
}

export function Secure_probes(sensorId, hours) {
    return Query_Secure_get(Api_Front_getProbes([sensorId, int32ToString(hours)]), uncurry(2, DataListSequence_get_Decoder()));
}

export function Secure_battery(sensorId) {
    return Query_Secure_get(Api_Front_getBattery(sensorId), uncurry(2, DataList_get_Decoder()));
}

export function Secure_refreshPicture(sensorId) {
    return Query_Secure_getNoCheck(Api_Front_refreshPicture(sensorId));
}

export function Secure_sensors() {
    return Query_Secure_get(Api_Sensor_list, (path_1, value_1) => list_8((path, value) => string(path, value), path_1, value_1));
}

export function Secure_status() {
    return Query_Secure_get(Api_Sensor_status, uncurry(2, GenericDocs$1_Decoder_Z1D7C0D26(uncurry(2, Sensor_Status_get_Decoder()))));
}

export function Secure_account() {
    return Query_Secure_get(Api_User_account, uncurry(2, User_get_Decoder()));
}

export function Secure_me() {
    return Query_Secure_get(Api_User_me, uncurry(2, UserLight_get_Decoder()));
}

export function Secure_updatePassword(newPass) {
    const body = UpdatePassword_Encode_Z547892B4(new UpdatePassword(newPass));
    return Query_Secure_post(Api_updatePassword, uncurry(2, Auth_ForgetPasswordResponse_get_Decoder()), body);
}

export function Secure_rollPassword(w1, w2, w3) {
    return Query_Secure_postRaw(Api_rollPassword, ChangePassword_Encode_Z70BDEC65(new ChangePassword(w1, w2, w3, "whatever man!")));
}

export function Secure_monthlyReport(sensorId, month, year) {
    return Query_Secure_getRaw(Api_Front_getData([sensorId, int32ToString(month), int32ToString(year)]), (result) => prepareProbeValuesAsString(sensorId, new OutputKind(0), result));
}

export function Secure_sensor(sensorId) {
    return Query_Secure_get(Api_Sensor_get(sensorId), uncurry(2, Types_Full_get_Decoder()));
}

export function Secure_toggleShare(sensor) {
    const encoded = Types_Full_EncodeForCouch_Z35750123(sensor);
    return Query_Secure_put(Api_Sensor_toggleShare, uncurry(2, UpdateResponse_get_Decoder()), encoded);
}

export function Secure_toggleShareView(sensor) {
    const encoded = Types_Full_EncodeForCouch_Z35750123(sensor);
    return Query_Secure_put(Api_Sensor_toggleShareView, uncurry(2, ShareUpdateResponse_get_Decoder()), encoded);
}

export function Secure_toggleAlarmOff(sensor) {
    const encoded = Types_Full_EncodeForCouch_Z35750123(sensor);
    return Query_Secure_put(Api_Sensor_toggleAlarmOff, uncurry(2, UpdateResponse_get_Decoder()), encoded);
}

export function Secure_updateSensor(sensor) {
    const encoded = Types_Full_EncodeForCouch_Z35750123(sensor);
    return Query_Secure_put(Api_Sensor_put, uncurry(2, UpdateResponse_get_Decoder()), encoded);
}

export function Secure_synchronize(args_0, args_1) {
    const args = [args_0, args_1];
    const encoded = Synchronize_Encode_586435E8(new Synchronize(args[0], toList(args[1])));
    return Query_Secure_post(Api_Front_synchronize, uncurry(2, Synchronize_get_Decoder()), encoded);
}

