import * as React from "react";

type Props = {
  name: string;
  refresh: (name: string) => void;
  isLoading: boolean;
  index: number;
};

const Component = (props: Props) => {
  const [isLoading, setIsLoading] = React.useState(props.isLoading);
  const [isSet, setIsSet] = React.useState(false);
  const isSetRef = React.useRef(isSet);
  isSetRef.current = isSet;

  const [count, setCount] = React.useState(props.index);
  const countRef = React.useRef(count);
  countRef.current = count;

  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (isSet) return;

      console.log("setting " + props.name + countRef.current);
      setIsSet(true);
      setInterval(() => {
        props.refresh(props.name);
        console.log("refreshing " + props.name);
        setIsLoading(props.isLoading);
      }, 1000 * 60);
      // }, 1000 * 60);
    }, 1000 * 10 * (countRef.current + 1));
    return () => {
      clearTimeout(timer);
    };
  }, [props.name]);

  return (
    <span className="icon has-text-light">
      <i className="fas fa-caret-right"> </i>
    </span>
  );
};

type SimpleRefresherProps = {
  refresh: () => void;
};

const SimpleRefresher = (props: SimpleRefresherProps) => {
  React.useEffect(() => {
    const interval = setInterval(() => {
      props.refresh();
    }, 1000 * 1);
    return () => clearInterval(interval);
  }, []);

  return null;
};

export { SimpleRefresher };
export default Component;
