import { toString, Record, Union } from "../../fable_modules/fable-library.3.7.0/Types.js";
import { Auth_ForgetPasswordResponse$reflection } from "../../Shared.fs.js";
import { Auth_changePassword, FetchError$reflection } from "../../Fetch.fs.js";
import { FSharpResult$2 } from "../../fable_modules/fable-library.3.7.0/Choice.js";
import { record_type, option_type, tuple_type, bool_type, list_type, string_type, class_type, union_type } from "../../fable_modules/fable-library.3.7.0/Reflection.js";
import { UI_createField, FormChecker_isOk, FormChecker_registerChecks, FormChecker_validateField, FormChecker_resetFields, FieldValidator_Check, FieldValidator_Required, FieldValidator_Check$reflection, FieldValidator_Required$reflection } from "../../Utils.fs.js";
import { FSharpMap__get_Item, ofSeq } from "../../fable_modules/fable-library.3.7.0/Map.js";
import { singleton, map, ofArray, empty } from "../../fable_modules/fable-library.3.7.0/List.js";
import { comparePrimitives } from "../../fable_modules/fable-library.3.7.0/Util.js";
import { Cmd_none, Cmd_OfFunc_result, Cmd_OfPromise_either } from "../../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { Option, button as button_1 } from "../../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import { DOMAttr } from "../../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { Inside_Heading_h6, Inside_Form_form, Inside_Section, Inside_Str, Inside_Notification, Inside_Span, Inside_Icon } from "../../GogoFulma.fs.js";
import { Fa_IconOption } from "../../fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { Screen, Color_IColor } from "../../fable_modules/Fulma.2.16.0/Common.fs.js";
import { IInputType } from "../../fable_modules/Fulma.2.16.0/Elements/Form/Input.fs.js";
import { columns } from "../../fable_modules/Fulma.2.16.0/Layouts/Columns.fs.js";
import { Option as Option_1, ISize, column } from "../../fable_modules/Fulma.2.16.0/Layouts/Column.fs.js";
import { h2 } from "../../fable_modules/Fulma.2.16.0/Elements/Heading.fs.js";

export class Types_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ChangePassword", "OnChangePassword", "OnError", "CheckField", "EnableAction", "MailSent", "UnknownEmail", "UnknownUser", "UnknownError", "Back"];
    }
}

export function Types_Msg$reflection() {
    return union_type("Page.Auth.ChangePassword.Types.Msg", [], Types_Msg, () => [[], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Auth_ForgetPasswordResponse$reflection(), FetchError$reflection()], FSharpResult$2, () => [[["ResultValue", Auth_ForgetPasswordResponse$reflection()]], [["ErrorValue", FetchError$reflection()]]])]], [["Item", class_type("System.Exception")]], [["Item1", string_type], ["Item2", string_type]], [], [], [], [], [], []]);
}

export class Types_Model extends Record {
    constructor(Fields, CanDoIt, FormError, ShowInstructions, Code) {
        super();
        this.Fields = Fields;
        this.CanDoIt = CanDoIt;
        this.FormError = FormError;
        this.ShowInstructions = ShowInstructions;
        this.Code = Code;
    }
}

export function Types_Model$reflection() {
    return record_type("Page.Auth.ChangePassword.Types.Model", [], Types_Model, () => [["Fields", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, tuple_type(FieldValidator_Required$reflection(), string_type, list_type(string_type), bool_type, list_type(FieldValidator_Check$reflection()))])], ["CanDoIt", bool_type], ["FormError", option_type(string_type)], ["ShowInstructions", bool_type], ["Code", string_type]]);
}

export function Types_initialModel(code) {
    return new Types_Model(ofSeq([["word1", [new FieldValidator_Required(0), "", empty(), false, ofArray([new FieldValidator_Check(0), new FieldValidator_Check(1, 3)])]], ["word2", [new FieldValidator_Required(0), "", empty(), false, ofArray([new FieldValidator_Check(0), new FieldValidator_Check(1, 3)])]], ["word3", [new FieldValidator_Required(0), "", empty(), false, ofArray([new FieldValidator_Check(0), new FieldValidator_Check(1, 3)])]]], {
        Compare: (x, y) => comparePrimitives(x, y),
    }), false, void 0, false, code);
}

export function Commands_getResult(handler, response) {
    return handler(response);
}

export function Commands_getError(handler, response) {
    return handler(response);
}

export function Commands_ChangePassword(code, w1, w2, w3) {
    return Cmd_OfPromise_either((tupledArg) => Auth_changePassword(tupledArg[0], tupledArg[1], tupledArg[2], tupledArg[3]), [code, w1, w2, w3], (response) => Commands_getResult((arg0) => (new Types_Msg(1, arg0)), response), (response_1) => Commands_getError((arg0_1) => (new Types_Msg(2, arg0_1)), response_1));
}

export function Update_fields(updated, model) {
    return new Types_Model(updated, model.CanDoIt, model.FormError, model.ShowInstructions, model.Code);
}

export function Update_error(error, model) {
    return new Types_Model(model.Fields, model.CanDoIt, error, model.ShowInstructions, model.Code);
}

export function Update_resetError(model) {
    return new Types_Model(model.Fields, model.CanDoIt, void 0, model.ShowInstructions, model.Code);
}

export function Update_showInstructions(model) {
    return new Types_Model(model.Fields, model.CanDoIt, model.FormError, true, model.Code);
}

export function Update_canDoAction(model) {
    return new Types_Model(model.Fields, true, model.FormError, model.ShowInstructions, model.Code);
}

export function Update_resetFields(model) {
    return Update_fields(FormChecker_resetFields(model.Fields, map((value) => value, ofArray(["word1", "word2", "word3"]))), model);
}

export function State_update(msg, model) {
    const getData = (field) => {
        const patternInput = FSharpMap__get_Item(model.Fields, toString(field));
        return [patternInput[1], patternInput[2]];
    };
    switch (msg.tag) {
        case 3: {
            const whatField = msg.fields[1];
            return [Update_resetError(Update_fields(FormChecker_validateField(whatField, FormChecker_registerChecks(msg.fields[0], whatField, model.Fields)), model)), Cmd_OfFunc_result(new Types_Msg(4))];
        }
        case 4: {
            if (FormChecker_isOk(model.Fields)) {
                return [Update_canDoAction(model), Cmd_none()];
            }
            else {
                return [model, Cmd_none()];
            }
        }
        case 1: {
            const response = msg.fields[0];
            if (response.tag === 1) {
                const err = response.fields[0];
                const updated_1 = FormChecker_registerChecks("", "word3", FormChecker_registerChecks("", "word2", FormChecker_registerChecks("", "word1", model.Fields)));
                switch (err.tag) {
                    case 1: {
                        return [new Types_Model(updated_1, model.CanDoIt, model.FormError, model.ShowInstructions, model.Code), Cmd_OfFunc_result(new Types_Msg(7))];
                    }
                    case 2: {
                        return [new Types_Model(updated_1, model.CanDoIt, model.FormError, model.ShowInstructions, model.Code), Cmd_OfFunc_result(new Types_Msg(7))];
                    }
                    default: {
                        return [new Types_Model(updated_1, model.CanDoIt, model.FormError, model.ShowInstructions, model.Code), Cmd_OfFunc_result(new Types_Msg(7))];
                    }
                }
            }
            else if (response.fields[0].EmailSent) {
                return [model, Cmd_OfFunc_result(new Types_Msg(5))];
            }
            else {
                return [model, Cmd_OfFunc_result(new Types_Msg(6))];
            }
        }
        case 2: {
            return [Update_error("Can\u0027t sign in: Server Unavailable", model), Cmd_none()];
        }
        case 0: {
            if (model.CanDoIt) {
                return [model, Commands_ChangePassword(model.Code, getData("word1")[0], getData("word2")[0], getData("word3")[0])];
            }
            else {
                return [Update_error("Unexpected Error!", model), Cmd_none()];
            }
        }
        default: {
            return [model, Cmd_none()];
        }
    }
}

export function View_root(model, dispatch) {
    const button = button_1(singleton(new Option(17, ofArray([new DOMAttr(40, (_arg1) => {
        dispatch(new Types_Msg(0));
    }), ["style", {
        backgroundColor: "#66C88E",
        cursor: "pointer",
    }]]))), ofArray([Inside_Icon(new Fa_IconOption(11, "fas fa-sign-in-alt")), Inside_Span("Mettre à jour mon mot de passe")]));
    let errors;
    const matchValue = model.FormError;
    errors = ((matchValue == null) ? "" : Inside_Notification(new Color_IColor(8), singleton(Inside_Str(matchValue))));
    let fields;
    const mkInput = (tabIndex, autoComplete, inputType, label, currentValue, errors_1, icon, onChange) => UI_createField(tabIndex, autoComplete, inputType, label, currentValue, errors_1, icon, onChange);
    const getData = (field) => {
        const patternInput = FSharpMap__get_Item(model.Fields, toString(field));
        return [patternInput[1], patternInput[2]];
    };
    const patternInput_1 = getData("word1");
    const patternInput_2 = getData("word2");
    const patternInput_3 = getData("word3");
    fields = Inside_Section(singleton(Inside_Form_form([Inside_Heading_h6(singleton(Inside_Str("Pour changer votre mot de passe, veuillez saisir trois mots. Ces trois mots formeront un nouveau mot de passe qui vous sera envoyé sur votre boîte email."))), mkInput(1, "w1", new IInputType(0), "Mot 1", patternInput_1[0], patternInput_1[1], new Fa_IconOption(11, "fas fa-code"), (inputValue) => {
        dispatch(new Types_Msg(3, inputValue, "word1"));
    }), mkInput(2, "w2", new IInputType(0), "Mot 2", patternInput_2[0], patternInput_2[1], new Fa_IconOption(11, "fas fa-code"), (inputValue_1) => {
        dispatch(new Types_Msg(3, inputValue_1, "word2"));
    }), mkInput(3, "w3", new IInputType(0), "Mot 3", patternInput_3[0], patternInput_3[1], new Fa_IconOption(11, "fas fa-code"), (inputValue_2) => {
        dispatch(new Types_Msg(3, inputValue_2, "word3"));
    })])));
    const login = button_1(ofArray([new Option(0, new Color_IColor(7)), new Option(17, ofArray([new DOMAttr(40, (_arg2) => {
        dispatch(new Types_Msg(9));
    }), ["style", {
        cursor: "pointer",
    }]]))]), ofArray([Inside_Icon(new Fa_IconOption(11, "fas fa-user")), Inside_Span("Annuler")]));
    return columns(empty(), ofArray([column(singleton(new Option_1(0, new Screen(0), new ISize(1))), singleton("")), column(singleton(new Option_1(0, new Screen(0), new ISize(1))), ofArray([h2(empty())(singleton("Changez votre mot de passe !")), fields, errors, columns(empty(), ofArray([column(empty(), singleton(button)), column(empty(), singleton(login))]))])), column(singleton(new Option_1(0, new Screen(0), new ISize(1))), singleton(""))]));
}

