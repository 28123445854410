import * as is_js from "../node_modules/is_js";
import { toString, FSharpRef, Union } from "./fable_modules/fable-library.3.7.0/Types.js";
import { union_type, int32_type } from "./fable_modules/fable-library.3.7.0/Reflection.js";
import { ofArray, append, singleton, empty, tail as tail_1, head as head_1, isEmpty, length as length_3, filter, map } from "./fable_modules/fable-library.3.7.0/List.js";
import { printf, toText } from "./fable_modules/fable-library.3.7.0/String.js";
import { isMatch } from "./fable_modules/fable-library.3.7.0/RegExp.js";
import { tryParse } from "./fable_modules/fable-library.3.7.0/Int32.js";
import { tryParse as tryParse_1 } from "./fable_modules/fable-library.3.7.0/Double.js";
import { empty as empty_1, singleton as singleton_1, collect, append as append_1, delay, map as map_1, toList } from "./fable_modules/fable-library.3.7.0/Seq.js";
import { map as map_2, toList as toList_1, filter as filter_1, FSharpMap__get_Count, FSharpMap__Add, FSharpMap__get_Item } from "./fable_modules/fable-library.3.7.0/Map.js";
import { div } from "./fable_modules/Fulma.2.16.0/Elements/Form/Field.fs.js";
import { Option, div as div_1 } from "./fable_modules/Fulma.2.16.0/Elements/Form/Control.fs.js";
import { DOMAttr, HTMLAttr } from "./fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { IInputType, Option as Option_1, input } from "./fable_modules/Fulma.2.16.0/Elements/Form/Input.fs.js";
import { TextSize_Option, Screen, Common_GenericOption, Modifier_IModifier, Size_ISize, Color_IColor } from "./fable_modules/Fulma.2.16.0/Common.fs.js";
import { Option as Option_2, icon as icon_1 } from "./fable_modules/Fulma.2.16.0/Elements/Icon.fs.js";
import { Fa_ISize, Fa_IconOption, Fa_i } from "./fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { value as value_1 } from "./fable_modules/fable-library.3.7.0/Option.js";
import { Option as Option_3, help } from "./fable_modules/Fulma.2.16.0/Elements/Form/Help.fs.js";
import { box$0027 } from "./fable_modules/Fulma.2.16.0/Elements/Box.fs.js";
import { title, heading, Item_Option, right, item, left, level } from "./fable_modules/Fulma.2.16.0/Layouts/Level.fs.js";
import { h5, Option as Option_4, h2 } from "./fable_modules/Fulma.2.16.0/Elements/Heading.fs.js";
import * as react from "react";
import { clear } from "./fable_modules/fable-library.3.7.0/Util.js";

export const FieldValidator_Is = is_js;

export class FieldValidator_Check extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["NotEmpty", "MinLength", "MaxLength", "ExactLength", "FrenchPhone", "Email", "IsInt", "IsFloat"];
    }
}

export function FieldValidator_Check$reflection() {
    return union_type("Utils.FieldValidator.Check", [], FieldValidator_Check, () => [[], [["Item", int32_type]], [["Item", int32_type]], [["Item", int32_type]], [], [], [], []]);
}

export class FieldValidator_Required extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Required", "Optional"];
    }
}

export function FieldValidator_Required$reflection() {
    return union_type("Utils.FieldValidator.Required", [], FieldValidator_Required, () => [[], []]);
}

export function FieldValidator_getErrors(rules) {
    return map((tuple_1) => tuple_1[1], filter((tuple) => tuple[0], rules));
}

export function FieldValidator_addCheck(value, check) {
    let outArg, outArg_1;
    switch (check.tag) {
        case 1: {
            const length = check.fields[0] | 0;
            const l = value.trim().length | 0;
            return [(l > 0) && (l < length), toText(printf("Il faut au moins %i caractères."))(length)];
        }
        case 2: {
            const length_1 = check.fields[0] | 0;
            const l_1 = value.trim().length | 0;
            return [(l_1 > 0) && (l_1 >= length_1), toText(printf("Cette valeur ne peut avoir plus de %i caractères."))(length_1)];
        }
        case 4: {
            return [!isMatch(/^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/g, value), "Ce numéro de téléphone ne paraît pas valide."];
        }
        case 5: {
            return [!FieldValidator_Is.email(value), "Cette adresse email ne paraît pas valide."];
        }
        case 6: {
            return [!((outArg = 0, [tryParse(value, 511, false, 32, new FSharpRef(() => outArg, (v) => {
                outArg = (v | 0);
            })), outArg]))[0], "Merci d\u0027indiquer un nombre entier"];
        }
        case 7: {
            return [!((outArg_1 = 0, [tryParse_1(value, new FSharpRef(() => outArg_1, (v_1) => {
                outArg_1 = v_1;
            })), outArg_1]))[0], "Merci d\u0027indiquer un nombre décimal"];
        }
        case 3: {
            const length_2 = check.fields[0] | 0;
            return [value.trim().length !== length_2, toText(printf("Cette valeur doit comporter exactement %i caractères."))(length_2)];
        }
        default: {
            return [value.trim().length <= 0, "Merci de renseigner ce champ."];
        }
    }
}

export function FieldValidator_validate(req, contents, checks) {
    const errors = FieldValidator_getErrors(toList(map_1((check) => FieldValidator_addCheck(contents, check), checks)));
    return [errors, (req.tag === 1) ? ((contents.trim().length > 0) ? (length_3(errors) <= 0) : true) : ((length_3(errors) <= 0) && (contents.trim().length > 0))];
}

export function FormChecker_extractFieldValue(field, fields) {
    return FSharpMap__get_Item(fields, field)[1];
}

export function FormChecker_registerChecks(value, field, fields) {
    const patternInput = FSharpMap__get_Item(fields, field);
    return FSharpMap__Add(fields, field, [patternInput[0], value, patternInput[2], patternInput[3], patternInput[4]]);
}

export function FormChecker_validateField(field, fields) {
    const patternInput = FSharpMap__get_Item(fields, field);
    const req = patternInput[0];
    const currentValue = patternInput[1];
    const checks = patternInput[4];
    const patternInput_1 = FieldValidator_validate(req, currentValue, checks);
    return FSharpMap__Add(fields, field, [req, currentValue, patternInput_1[0], patternInput_1[1], checks]);
}

export function FormChecker_isOk(fields) {
    return FSharpMap__get_Count(filter_1((_arg5, tupledArg) => tupledArg[3], fields)) >= FSharpMap__get_Count(fields);
}

export function FormChecker_invalidFields(fields) {
    return map((tuple) => tuple[0], toList_1(map_2((field, tupledArg_1) => field, filter_1((_arg5, tupledArg) => (!tupledArg[3]), fields))));
}

export function FormChecker_resetFields(fields, list) {
    const resetFields = (updatedFields_mut, list_1_mut) => {
        resetFields:
        while (true) {
            const updatedFields = updatedFields_mut, list_1 = list_1_mut;
            if (isEmpty(list_1)) {
                return updatedFields;
            }
            else {
                updatedFields_mut = FormChecker_registerChecks("", toString(head_1(list_1)), updatedFields);
                list_1_mut = tail_1(list_1);
                continue resetFields;
            }
            break;
        }
    };
    return resetFields(fields, list);
}

export function UI_createField(tabIndex, autoComplete, inputType, label, currentValue, errors, icon, onChange) {
    let arg10;
    const hasErrors = length_3(errors) > 0;
    const hasSomething = currentValue.length > 0;
    const isSuccess = (!hasErrors) && hasSomething;
    return div(empty(), singleton(div_1(singleton(new Option(5, singleton(new HTMLAttr(64, "control has-icons-left has-icons-right ")))), ofArray([input(append((tabIndex <= 1) ? ofArray([new Option_1(1, inputType), new Option_1(12, label), new Option_1(8, currentValue), new Option_1(15, ofArray([new HTMLAttr(156, tabIndex), new DOMAttr(9, (e) => {
        onChange(e.target.value);
    }), new HTMLAttr(54, autoComplete)]))]) : ofArray([new Option_1(1, inputType), new Option_1(12, label), new Option_1(8, currentValue), new Option_1(15, ofArray([new HTMLAttr(156, tabIndex), new DOMAttr(9, (e_1) => {
        onChange(e_1.target.value);
    }), new HTMLAttr(54, autoComplete)]))]), singleton(hasErrors ? (new Option_1(2, new Color_IColor(8))) : (hasSomething ? (new Option_1(2, new Color_IColor(5))) : (new Option_1(2, new Color_IColor(4))))))), hasErrors ? icon_1(ofArray([new Option_2(0, new Size_ISize(0)), new Option_2(2)]), singleton(Fa_i(singleton(new Fa_IconOption(11, "fas fa-sign-in-alt")), []))) : (isSuccess ? icon_1(ofArray([new Option_2(0, new Size_ISize(0)), new Option_2(2)]), singleton(Fa_i(singleton(new Fa_IconOption(11, "fas fa-check")), []))) : ""), (icon != null) ? icon_1(ofArray([new Option_2(0, new Size_ISize(0)), new Option_2(1)]), singleton(Fa_i(singleton(new Fa_IconOption(15, (arg10 = toString(value_1(icon)), toText(printf("%s"))(arg10)))), []))) : "", hasErrors ? help(ofArray([new Option_3(2, new Color_IColor(8)), new Option_3(1, singleton(["style", {
        fontFamily: "Open Sans",
    }]))]), singleton(head_1(errors))) : ""]))));
}

export function UI_createNumberFieldSimple(tabIndex, autoComplete, min, max, label, errors, icon, onChange) {
    let arg10;
    const hasErrors = length_3(errors) > 0;
    const isSuccess = !hasErrors;
    return div(empty(), singleton(div_1(singleton(new Option(5, singleton(new HTMLAttr(64, "control has-icons-left has-icons-right ")))), ofArray([input(append((tabIndex <= 1) ? ofArray([new Option_1(1, new IInputType(7)), new Option_1(12, label), new Option_1(15, ofArray([new HTMLAttr(156, tabIndex), new DOMAttr(9, (e) => {
        onChange(e.target.value);
    }), new HTMLAttr(119, min), new HTMLAttr(114, max), new HTMLAttr(54, autoComplete)]))]) : ofArray([new Option_1(1, new IInputType(7)), new Option_1(12, label), new Option_1(15, ofArray([new HTMLAttr(156, tabIndex), new DOMAttr(9, (e_1) => {
        onChange(e_1.target.value);
    }), new HTMLAttr(54, autoComplete), new HTMLAttr(119, min), new HTMLAttr(114, max)]))]), singleton(hasErrors ? (new Option_1(2, new Color_IColor(8))) : (new Option_1(2, new Color_IColor(4)))))), hasErrors ? icon_1(ofArray([new Option_2(0, new Size_ISize(0)), new Option_2(2)]), singleton(Fa_i(singleton(new Fa_IconOption(11, "fas fa-sign-in-alt")), []))) : (isSuccess ? icon_1(ofArray([new Option_2(0, new Size_ISize(0)), new Option_2(2)]), singleton(Fa_i(singleton(new Fa_IconOption(11, "fas fa-check")), []))) : ""), (icon != null) ? icon_1(ofArray([new Option_2(0, new Size_ISize(0)), new Option_2(1)]), singleton(Fa_i(singleton(new Fa_IconOption(15, (arg10 = toString(value_1(icon)), toText(printf("%s"))(arg10)))), []))) : "", hasErrors ? help(ofArray([new Option_3(2, new Color_IColor(8)), new Option_3(1, singleton(["style", {
        fontFamily: "Open Sans",
    }]))]), singleton(head_1(errors))) : ""]))));
}

export function UI_createFieldSimple(tabIndex, autoComplete, inputType, label, errors, icon, onChange) {
    let arg10;
    const hasErrors = length_3(errors) > 0;
    const isSuccess = !hasErrors;
    return div(empty(), singleton(div_1(singleton(new Option(5, singleton(new HTMLAttr(64, "control has-icons-left has-icons-right ")))), ofArray([input(append((tabIndex <= 1) ? ofArray([new Option_1(1, inputType), new Option_1(12, label), new Option_1(15, ofArray([new HTMLAttr(156, tabIndex), new DOMAttr(9, (e) => {
        onChange(e.target.value);
    }), new HTMLAttr(54, autoComplete)]))]) : ofArray([new Option_1(1, inputType), new Option_1(12, label), new Option_1(15, ofArray([new HTMLAttr(156, tabIndex), new DOMAttr(9, (e_1) => {
        onChange(e_1.target.value);
    }), new HTMLAttr(54, autoComplete)]))]), singleton(hasErrors ? (new Option_1(2, new Color_IColor(8))) : (new Option_1(2, new Color_IColor(4)))))), hasErrors ? icon_1(ofArray([new Option_2(0, new Size_ISize(0)), new Option_2(2)]), singleton(Fa_i(singleton(new Fa_IconOption(11, "fas fa-sign-in-alt")), []))) : (isSuccess ? icon_1(ofArray([new Option_2(0, new Size_ISize(0)), new Option_2(2)]), singleton(Fa_i(singleton(new Fa_IconOption(11, "fas fa-check")), []))) : ""), (icon != null) ? icon_1(ofArray([new Option_2(0, new Size_ISize(0)), new Option_2(1)]), singleton(Fa_i(singleton(new Fa_IconOption(15, (arg10 = toString(value_1(icon)), toText(printf("%s"))(arg10)))), []))) : "", hasErrors ? help(ofArray([new Option_3(2, new Color_IColor(8)), new Option_3(1, singleton(["style", {
        fontFamily: "Open Sans",
    }]))]), singleton(head_1(errors))) : ""]))));
}

export function UI_createFieldSimple2(value, tabIndex, autoComplete, inputType, label, errors, icon, onChange) {
    let arg10;
    const hasErrors = length_3(errors) > 0;
    const isSuccess = !hasErrors;
    return div(empty(), singleton(div_1(singleton(new Option(5, singleton(new HTMLAttr(64, "control has-icons-left has-icons-right ")))), ofArray([input(append((tabIndex <= 1) ? ofArray([new Option_1(1, inputType), new Option_1(12, label), new Option_1(15, ofArray([new HTMLAttr(156, tabIndex), new DOMAttr(9, (e) => {
        onChange(e.target.value);
    }), new HTMLAttr(54, autoComplete)]))]) : ofArray([new Option_1(1, inputType), new Option_1(12, label), new Option_1(15, ofArray([new HTMLAttr(156, tabIndex), new DOMAttr(9, (e_1) => {
        onChange(e_1.target.value);
    }), new HTMLAttr(54, autoComplete)]))]), singleton(hasErrors ? (new Option_1(2, new Color_IColor(8))) : (new Option_1(2, new Color_IColor(4)))))), hasErrors ? icon_1(ofArray([new Option_2(0, new Size_ISize(0)), new Option_2(2)]), singleton(Fa_i(singleton(new Fa_IconOption(11, "fas fa-sign-in-alt")), []))) : (isSuccess ? icon_1(ofArray([new Option_2(0, new Size_ISize(0)), new Option_2(2)]), singleton(Fa_i(singleton(new Fa_IconOption(11, "fas fa-check")), []))) : ""), (icon != null) ? icon_1(ofArray([new Option_2(0, new Size_ISize(0)), new Option_2(1)]), singleton(Fa_i(singleton(new Fa_IconOption(15, (arg10 = toString(value_1(icon)), toText(printf("%s"))(arg10)))), []))) : "", hasErrors ? help(ofArray([new Option_3(2, new Color_IColor(8)), new Option_3(1, singleton(["style", {
        fontFamily: "Open Sans",
    }]))]), singleton(head_1(errors))) : ""]))));
}

export function UI_buttonBar(name, backAction) {
    let children;
    return box$0027(ofArray([new Common_GenericOption(2, ofArray([new Modifier_IModifier(0, new Color_IColor(1)), new Modifier_IModifier(1, new Color_IColor(7))])), new Common_GenericOption(1, ofArray([new DOMAttr(40, backAction), ["style", {
        cursor: "pointer",
    }]]))]), singleton(level(empty(), ofArray([left(empty(), singleton(item(empty(), singleton(h2(singleton(new Option_4(9, singleton(["style", {
        color: "#66C88E",
    }]))))(singleton(name)))))), right(empty(), singleton(item(singleton(new Item_Option(1)), singleton((children = [heading(empty(), singleton(Fa_i(ofArray([new Fa_IconOption(11, "fas fa-chevron-circle-up"), new Fa_IconOption(0, new Fa_ISize(3))]), []))), title(singleton(new Common_GenericOption(2, ofArray([new Modifier_IModifier(1, new Color_IColor(7)), new Modifier_IModifier(3, new Screen(0), new TextSize_Option(4))]))), singleton("retour"))], react.createElement("div", {}, ...children))))))]))));
}

export function UI_buttonBarForModule(name, moduleId, date, backAction) {
    return box$0027(ofArray([new Common_GenericOption(2, ofArray([new Modifier_IModifier(0, new Color_IColor(1)), new Modifier_IModifier(1, new Color_IColor(7))])), new Common_GenericOption(1, ofArray([new DOMAttr(40, backAction), ["style", {
        cursor: "pointer",
    }]]))]), singleton(level(empty(), ofArray([left(empty(), singleton(item(empty(), singleton(h2(singleton(new Option_4(9, singleton(["style", {
        color: "#66C88E",
    }]))))(singleton(name)))))), right(empty(), singleton(item(singleton(new Item_Option(0, singleton(["style", {
        color: "whitesmoke",
    }]))), singleton(date))))]))));
}

export function UI_disabledButton(name) {
    return box$0027(ofArray([new Common_GenericOption(2, ofArray([new Modifier_IModifier(0, new Color_IColor(2)), new Modifier_IModifier(1, new Color_IColor(1)), new Modifier_IModifier(26, new Screen(3), true)])), new Common_GenericOption(1, singleton(["style", {
        cursor: "not-allowed",
    }]))]), singleton(level(empty(), ofArray([left(empty(), singleton(item(empty(), singleton(h5(empty())(singleton(name)))))), right(empty(), singleton(item(singleton(new Item_Option(1)), singleton(Fa_i(singleton(new Fa_IconOption(11, "fas fa-ban")), [])))))]))));
}

export function UI_subButtonBar(name) {
    return box$0027(ofArray([new Common_GenericOption(2, ofArray([new Modifier_IModifier(0, new Color_IColor(1)), new Modifier_IModifier(1, new Color_IColor(5))])), new Common_GenericOption(1, singleton(["style", {
        cursor: "pointer",
    }]))]), singleton(level(empty(), ofArray([left(empty(), singleton(item(empty(), singleton(h5(singleton(new Option_4(9, singleton(["style", {
        color: "#66C88E",
    }]))))(singleton(name)))))), right(empty(), singleton(item(singleton(new Item_Option(1)), singleton(Fa_i(singleton(new Fa_IconOption(11, "fas fa-chevron-circle-right")), [])))))]))));
}

export function Seq_split(n, s) {
    return delay(() => {
        const r = [];
        return append_1(collect((x) => {
            void (r.push(x));
            return (r.length === n) ? append_1(singleton_1(r.slice()), delay(() => {
                clear(r);
                return empty_1();
            })) : empty_1();
        }, s), delay(() => ((r.length !== 0) ? singleton_1(r.slice()) : empty_1())));
    });
}

